import { Box, ScrollView, VStack } from "native-base";
import React, { Fragment } from "react";

import { ClientCard } from "../../components";
import { h8 } from "../../styles";

interface IClientListingParameter {
  clients: IClientItems[];
  setDeleteOpen: (value: boolean) => void;
  setEditOpen: (value: boolean) => void;
  setSelectedClient: (value: IClientItems) => void;
}

export const ClientListingParameter = ({ clients, setDeleteOpen, setEditOpen, setSelectedClient }: IClientListingParameter) => {
  return (
    <Fragment>
      <ScrollView>
        {/* show completed item */}
        <Box height={4} />

        <VStack space="4">
          {clients.sort().map((eachClient, index) => {
            return (
              <Fragment key={index}>
                <ClientCard
                  key={`${index}${eachClient.reference}`}
                  // {...sampleCardProps}
                  cardItem={eachClient}
                  setDeleteOpen={setDeleteOpen}
                  setEditOpen={setEditOpen}
                  setSelectedClient={setSelectedClient}
                />
                {index < clients.length - 1 ? <Box height={1} borderTopWidth={1} opacity={0.3} marginX={h8} /> : null}
              </Fragment>
            );
          })}
        </VStack>
      </ScrollView>
    </Fragment>
  );
};
