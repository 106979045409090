import CryptoJS from "crypto-js";
import { Box, Center, Input, Spacer, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, GeneralButton, Loading, LoginContainer } from "../../components";
import { AUTH_SIGNUP, ERROR_MESSAGE, LOGIN } from "../../constants";
import { h24, h8, w16, w4, w8 } from "../../styles";

export const SignUp = () => {
  const navigate = useNavigate();

  const [fetching, setFetching] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [matchPassword, setMatchPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleResetCredential = () => {
    setEmail("");
    setPassword("");
    setMatchPassword("");
  };

  const handleRegister = async () => {
    setFetching(true);

    if (email !== "" && password !== "" && password === matchPassword) {
      const authRequest = {
        email: email,
        password: CryptoJS.AES.encrypt(password, "P@E!$%TROLAB+").toString(),
      };
      try {
        const authSignUpResponse = await fetch(AUTH_SIGNUP, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(authRequest),
        });
        const authSignUpContent = await authSignUpResponse.json();
        // console.log("authSignUpContent", authSignUpContent);

        if (authSignUpContent.data.message === "Success") {
          // go to dashboard
          navigate("/login");
        } else {
          // console.log("failed to add new parameter");
          setError(true);
          setErrorMessage("ERROR_MESSAGE: FAILED TO SIGN UP");
        }
      } catch (error) {
        // console.log(error);
        setError(true);
        setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
      }
    } else if (password !== matchPassword) {
      setError(true);
      setErrorMessage(ERROR_MESSAGE.NOT_MATCHING_PASSWORD);
    } else {
      setError(true);
      setErrorMessage(ERROR_MESSAGE.INPUT_CREDENTIAL);
    }

    setFetching(false);
    handleResetCredential();
  };

  const handleGoToLogin = () => {
    navigate("/login");
  };

  const handleUpdateShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Center>
      <LoginContainer title={"PetroLab\nAdmin"} subtitle={"V 1.0"} subLabel={""}>
        <Box>
          <VStack space={4} marginBottom={h8}>
            <Input
              autoCapitalize={"none"}
              onChangeText={setEmail}
              placeholder={LOGIN.PLACEHOLDER_EMAIL}
              value={email}
              variant="filled"
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />

            <Input
              maxLength={8}
              onChangeText={setPassword}
              placeholder={LOGIN.PLACEHOLDER_PASSWORD}
              // secureTextEntry={true}
              value={password}
              variant="filled"
              type={showPassword ? "text" : "password"}
              InputRightElement={
                <div onClick={handleUpdateShowPassword} style={{ cursor: "pointer", paddingRight: w4 }}>
                  <Text>{showPassword ? "hide" : "show"}</Text>
                </div>
              }
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />

            <Input
              maxLength={8}
              onChangeText={setMatchPassword}
              placeholder={LOGIN.PLACEHOLDER_MATCH_PASSWORD}
              // secureTextEntry={true}
              value={matchPassword}
              variant="filled"
              type={showPassword ? "text" : "password"}
              InputRightElement={
                <div onClick={handleUpdateShowPassword} style={{ cursor: "pointer", paddingRight: w4 }}>
                  <Text>{showPassword ? "hide" : "show"}</Text>
                </div>
              }
              style={{
                padding: w16,
                borderRadius: 4,
                width: "auto",
              }}
            />
          </VStack>

          <Spacer space={h24} />
          <GeneralButton
            label={LOGIN.BUTTON_SIGNUP}
            showIcon={true}
            iconSize={h24}
            filled={false}
            disabled={fetching}
            onPress={handleRegister}
            signUp={false}
            commentText={LOGIN.LABEL_LOGIN}
            commentOnPress={handleGoToLogin}
          />
        </Box>
      </LoginContainer>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* view show loading */}
      {fetching ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null}
    </Center>
  );
};
