import { Box, Image, Text } from "native-base";
import React from "react";

import { h2, h24, h36 } from "../../styles";
import { Spacer } from "../view";

interface ILoginContainer {
  children: JSX.Element;
  subtitle: string;
  subLabel: string;
  title: string;
}

export const LoginContainer = ({ children, subtitle, subLabel, title }: ILoginContainer) => {
  const defaultSubtitleStyle = { fontSize: 12 };

  return (
    <Box alignItems={"center"} height={"100vh"} width={"100vw"} justifyContent={"center"} padding={16}>
      {/* TODO image / title of login */}
      <Box flexDirection={"row"} alignItems={"center"} justifyContent={"start"}>
        <Image
          size={200}
          alt="petrolab logo"
          borderRadius={100}
          // source={{
          //   uri: "../../assets//Logo Petrolab final.jpg",
          // }}
          source={require("../../assets/Logo Petrolab final.jpg")}
          fallbackSource={{
            uri: "https://www.w3schools.com/css/img_lights.jpg",
          }}
        />

        <Box justifyContent={"start"} alignItems={"start"}>
          <Text fontSize={36} fontWeight={"bold"} color={"darkBlue.800"}>
            {title}
          </Text>
          <Spacer space={h2} />
          <Text fontSize={12} fontWeight={"normal"} color={"darkBlue.700"} opacity={0.8}>
            {subLabel}
          </Text>
        </Box>
      </Box>
      <Spacer space={h36} />

      {children}

      {/* TODO footer of login */}
      <React.Fragment>
        <Spacer space={h24} />
        <p style={defaultSubtitleStyle}>{subtitle ? subtitle : null}</p>
      </React.Fragment>
    </Box>
  );
};
