import { MenuItem, Pagination, TextField } from "@mui/material";
import { Box } from "native-base";
import { useContext } from "react";

import { ContentContainer, CustomTabContainer, ListingTitle, Spacer } from "../../components";
import { GlobalContext, ISearchDashboard } from "../../context";
import { getLocalStorage } from "../../utils";
import { ITabRoutes } from "../Dashboard";
import { SearchDashboard } from "./SearchDashboard";
import { h125, h128, h2, h4, h8, w100, w280 } from "../../styles";
import { useNavigate } from "react-router-dom";

interface IPlantOverview {
  children: JSX.Element;
  tabRoutes: ITabRoutes[];
  handleUpdateTab: (value: string) => void;
  handleUpdatePagination: (value: number) => void;
  handleUpdateSearchDashboard: (searchDashboard: ISearchDashboard) => void;
  activeTab: string;
}

interface IAddActions {
  title: string;
  onPress: () => void;
}

export const PlantOverview = ({
  children,
  tabRoutes,
  activeTab,
  handleUpdateTab,
  handleUpdatePagination,
  handleUpdateSearchDashboard,
}: IPlantOverview) => {
  const { globalState } = useContext(GlobalContext);
  const { sampleAmount } = globalState;
  const { currentPage } = sampleAmount;
  const navigate = useNavigate();

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleUpdatePagination(value);
  };
  const totalPage = parseInt(getLocalStorage("total-page-dashboard"), 10);

  const handleAddOneSample = () => {
    navigate("/add-one-sample");
  };

  const handleAddMultipleSample = () => {
    navigate("/add-multiple-sample");
  };

  const ADD_NEW_ACTIONS: IAddActions[] = [
    {
      title: "Add One Sample",
      onPress: handleAddOneSample,
    },
    {
      title: "Add Samples",
      onPress: handleAddMultipleSample,
    },
  ];

  return (
    <ContentContainer>
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          borderRadius: 12,
          // boxShadow: "40 40",
          borderWidth: 1,
          borderColor: "#31304D",
          borderStyle: "solid",
          height: h128,
        }}>
        <Box flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} paddingY={h2}>
          <Box flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <CustomTabContainer activeTab={activeTab} handleUpdateTab={handleUpdateTab} tabRoutes={tabRoutes} />
            <Spacer space={h8} isHorizontal={true} />
            <SearchDashboard onPress={handleUpdateSearchDashboard} />
          </Box>

          <Box flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Pagination
              size="small"
              page={currentPage}
              count={totalPage}
              color="standard"
              onChange={handlePageChange}
              style={{ width: w280 }}
            />
            <Spacer space={h8} isHorizontal={true} />
            <TextField
              // id="outlined-select-currency"
              select
              size={"small"}
              label="New +"
              value={"Add One Sample"}
              style={{ width: w100 }}
              // placeholder="New +"
              // helperText="Add New"
            >
              {ADD_NEW_ACTIONS.map((option) => {
                const { title, onPress } = option;
                return (
                  <MenuItem key={title} value={title} onClick={onPress}>
                    {title}
                  </MenuItem>
                );
              })}
            </TextField>
            <Spacer space={h8} isHorizontal={true} />
          </Box>
        </Box>

        <div
          style={{
            backgroundColor: "#31304D",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <ListingTitle />
          <Spacer space={h4} />
        </div>
      </div>
      {children}
    </ContentContainer>
  );
};
