const baseWidth = 320;
const baseHeight = 720;

export const autoHeight = (size: number) => {
  return size < baseHeight ? size : size / 3;
};

export const autoWidth = (size: number) => {
  return size < baseWidth ? size : size / 3;
};
