import { Center, Text } from "native-base";
import React from "react";

export const Loading = () => {
  return (
    <Center
      backgroundColor={"blueGray.100"}
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Text>Loading ...</Text>
    </Center>
  );
};
