import { Box, FormControl, Input, Text, View } from "native-base";
import React, { useEffect, useState } from "react";

import { Spacer } from "../../components";
import { h8, w16 } from "../../styles";

interface IUpdateClient {
  setClientRequest: (request: Omit<IClientItems, "reference">) => void;
  selectedClient: IClientItems | undefined;
  formErrorMessage: string;
  setFormErrorMessage: (value: string) => void;
}

export const UpdateClient = ({ setClientRequest, selectedClient, formErrorMessage }: IUpdateClient) => {
  const [clientName, setClientName] = useState<string>("");
  const [clientAddress, setClientAddress] = useState<string>("");
  const [clientEmail, setClientEmail] = useState<string>("");

  const handleUpdateClientName = (value: string) => {
    setClientName(value);
  };
  const handleUpdateClientAddress = (value: string) => {
    setClientAddress(value);
  };
  const handleUpdateClientEmail = (value: string) => {
    setClientEmail(value);
  };

  const handleUpdateRequest = () => {
    const request: Omit<IClientItems, "reference"> = {
      clientEmail: clientEmail,
      clientName: clientName,
      clientAddress: clientAddress,
      clientId: selectedClient?.clientId !== undefined ? selectedClient?.clientId : "",
    };
    setClientRequest(request);
  };

  useEffect(() => {
    handleUpdateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName, clientAddress, clientEmail]);

  useEffect(() => {
    return () => {
      setClientName("");
      setClientAddress("");
      setClientEmail("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box paddingBottom={4}>
      <FormControl>
        <FormControl.Label>Client Email *</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateClientEmail}
          placeholder={"email"}
          value={clientEmail}
          variant="outline"
          // isDisabled={true}
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
        <Text textAlign={"start"} fontWeight={"semibold"}>
          ** {selectedClient?.clientEmail || "-"}
        </Text>
        {formErrorMessage !== "" && formErrorMessage.includes("email") ? (
          <View>
            <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
              **{formErrorMessage}
            </Text>
          </View>
        ) : null}
        <Spacer space={h8} />

        <FormControl.Label>Client Name *</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateClientName}
          placeholder={"name"}
          value={clientName}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
        <Text textAlign={"start"} fontWeight={"semibold"}>
          ** {selectedClient?.clientName || "-"}
        </Text>
        {formErrorMessage !== "" && formErrorMessage.includes("name") ? (
          <View>
            <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
              **{formErrorMessage}
            </Text>
          </View>
        ) : null}
        <Spacer space={h8} />

        <FormControl.Label>Client Address</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateClientAddress}
          placeholder={"Address"}
          value={clientAddress}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
        <Text textAlign={"start"} fontWeight={"semibold"}>
          ** {selectedClient?.clientAddress || "-"}
        </Text>
        <Spacer space={h8} />
      </FormControl>

      {/* view show loading */}
      {/* {state.isSubmitted ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null} */}
    </Box>
  );
};
