import { Box, Divider, HStack } from "native-base";
import React from "react";

import { ListingLabel } from "./ListingLabel";

export const ListingTitleClient = () => {
  return (
    <Box
      px="2"
      py="2"
      // borderRadius={4}
      mx="auto"
      // shadow={4}
      // backgroundColor="white"
      width={window.innerWidth - 250}>
      <HStack
        space={4}
        // justifyContent="space-between"
        alignItems="center"
        divider={
          <Divider
            my="2"
            thickness={1}
            _light={{
              bg: "tertiary.500",
            }}
          />
        }>
        <ListingLabel label={"Company Id"} customFlex={0.5} />
        <ListingLabel label={"Company Name"} customFlex={2} />
        <ListingLabel label={"Company email"} />
        <ListingLabel label={"Company Address"} />
        <ListingLabel label={"Action +"} customFlex={0.4} />
      </HStack>
    </Box>
  );
};
