import React, { createContext, FunctionComponent, ReactNode, useState } from "react";

import { getLocalStorage, setLocalStorage } from "../utils";

// ? how to implement result of each test ? Will add 2 new field in each of the tests selected. a string for current result & an array of string for previous results

export interface ISample {
  reference: string;
  batchNumber: string;
  clientName: string;
  clientId: string;
  clientRequestor: string;
  sampleLocation: string;
  touchPoint: string;
  receivedDate: string;
  clientEmail: string;
  sampleName: string;
  sampleId: string;
  isCompleted: boolean;
  sampleType: string;
  samplingDate: string;
  reportRef: string[];
}
// clientId
// clientName
// clientAddress
// sampleLocation
// clientRequestor
//       sampleId
// isPackageTest
// testComment
//       sampleType
// sampleName
// sampleAge
// isFresh
//       samplingDate
// receivedDate
// testingStartDate
// testingFinishDate
// reportedDate
//       equipmentUnitId
// poReference
//       sampleResult
// sampleCurrentDate
// samplePreviousDate
// sampleInterpretation
// sampleDiagnosedBy
//       isCompleted

// currentResult
// parameterCategory
// parameterMethod
// parameterName
// parameterUnit
// previousResult
// productTypical
// specification

export interface ISampleAmount {
  currentPage: number;
  ongoingDocs: string | number;
  completedDocs: string | number;
}

export type TByCategory = "sampleId" | "clientName" | "sampleName";
export interface ISearchDashboard {
  searchText: string;
  byCategory: TByCategory;
  categoryItems: TByCategory[];
}

interface IGlobalState {
  adminEmail: string;
  allSamplesForAdmin: ISample[];
  completedSamples: ISample[];
  ongoingSample: ISample[];
  menuItems: string[];
  permissionLevel: string;
  selectedMenu: string;
  sessionId: string;
  adminPermissionLevel: string;
  selectedSampleRef: string;
  isFetching: boolean;
  sampleAmount: ISampleAmount;
  searchDashboard: ISearchDashboard;
}

export interface IGlobalContext {
  globalState: IGlobalState;
  handleResetGlobal: () => void;
  handleInitAllSamplesAdmin: (
    adminEmail: string,
    allSamplesForAdmin: ISample[],
    sampleAmount: ISampleAmount,
    adminLevel?: string,
    searchDashboard?: ISearchDashboard,
  ) => void;
  handleUpdateSelectedMenu: (value: string, resetSearchDashboard?: boolean) => void;
  handleUpdateSelectedSampleRef: (value: string) => void;
  handleUpdateIsFetching: (value: boolean) => void;
  handleUpdateSearchDashboard: (searchDashboard: ISearchDashboard) => void;
  handleResetSearchDashboard: () => void;
}

interface IGlobalProvider {
  children: ReactNode;
}

const isLowerLevelAdmin = ["Analysis"];
const isHigherLevelAdmin = ["Analysis", "Client", "Permission", "Parameter"];

const initialState: IGlobalState = {
  adminEmail: "",
  allSamplesForAdmin: [],
  completedSamples: [],
  ongoingSample: [],
  menuItems: getLocalStorage("admin-level") === "2" ? isHigherLevelAdmin : isLowerLevelAdmin,
  permissionLevel: "",
  selectedMenu: "Analysis",
  sessionId: "",
  selectedSampleRef: "",
  isFetching: false,
  sampleAmount: {
    currentPage: 1,
    ongoingDocs: 0,
    completedDocs: 0,
  },
  searchDashboard: {
    searchText: "",
    byCategory: "sampleId",
    categoryItems: ["sampleId", "clientName", "sampleName"],
  },
  adminPermissionLevel:
    getLocalStorage("admin-level") !== null && typeof getLocalStorage("admin-level") === "string" ? getLocalStorage("admin-level") : "1",
};

export const GlobalContext = createContext<IGlobalContext>({
  globalState: initialState,
  handleResetGlobal: () => {},
  handleInitAllSamplesAdmin: () => {},
  handleUpdateSelectedMenu: () => {},
  handleUpdateSelectedSampleRef: () => {},
  handleUpdateIsFetching: () => {},
  handleUpdateSearchDashboard: () => {},
  handleResetSearchDashboard: () => {},
});

const { Provider } = GlobalContext;

export const GlobalProvider: FunctionComponent<IGlobalProvider> = ({ children }: IGlobalProvider) => {
  const [globalState, setGlobalState] = useState<IGlobalState>(initialState);

  const handleResetGlobal = () => {
    setGlobalState({ ...initialState });
    // reset local storage
    setLocalStorage("admin-level", "");
    setLocalStorage("id-token", "");
  };

  const handleUpdateSelectedMenu = (value: string, resetSearchDashboard?: boolean) => {
    const updatedSearchDashboard: ISearchDashboard =
      resetSearchDashboard !== undefined && resetSearchDashboard === true
        ? {
            searchText: "",
            byCategory: globalState.searchDashboard.categoryItems[0],
            categoryItems: ["sampleId", "clientName", "sampleName"],
          }
        : {
            searchText: globalState.searchDashboard.searchText,
            byCategory: globalState.searchDashboard.byCategory,
            categoryItems: ["sampleId", "clientName", "sampleName"],
          };
    setGlobalState({ ...globalState, selectedMenu: value, searchDashboard: updatedSearchDashboard });
  };

  const handleInitAllSamplesAdmin = (
    adminEmail: string,
    allSamplesForAdmin: ISample[],
    sampleAmount: ISampleAmount,
    adminLevel?: string,
    searchDashboard?: ISearchDashboard,
  ) => {
    // console.log("ctx fx", {
    //   clientProfile: clientProfile,
    //   allSamples: allSamples,
    // });
    // console.log("allSamplesForAdmin", allSamplesForAdmin);

    const isOngoingSamples = allSamplesForAdmin.some((eachSample) => eachSample.isCompleted === false);
    setGlobalState({
      ...globalState,
      adminEmail: adminEmail,
      adminPermissionLevel: adminLevel !== undefined ? adminLevel : globalState.adminPermissionLevel,
      allSamplesForAdmin: [...allSamplesForAdmin],
      completedSamples: isOngoingSamples ? [] : allSamplesForAdmin,
      ongoingSample: isOngoingSamples ? allSamplesForAdmin : [],
      sampleAmount: sampleAmount,
      searchDashboard: searchDashboard !== undefined ? searchDashboard : globalState.searchDashboard,
    });
  };

  const handleUpdateSelectedSampleRef = (value: string) => {
    setGlobalState({
      ...globalState,
      selectedSampleRef: value,
    });
  };

  const handleUpdateIsFetching = (value: boolean) => {
    setGlobalState({
      ...globalState,
      isFetching: value,
    });
  };

  const handleUpdateSearchDashboard = (searchDashboard: ISearchDashboard) => {
    setGlobalState({
      ...globalState,
      searchDashboard: searchDashboard,
    });
  };

  const handleResetSearchDashboard = () => {
    setGlobalState({
      ...globalState,
      searchDashboard: {
        searchText: "",
        byCategory: "sampleId",
        categoryItems: ["sampleId"],
      },
    });
  };

  return (
    <Provider
      value={{
        globalState,
        handleResetGlobal,
        handleInitAllSamplesAdmin,
        handleUpdateSelectedMenu,
        handleUpdateSelectedSampleRef,
        handleUpdateIsFetching,
        handleUpdateSearchDashboard,
        handleResetSearchDashboard,
      }}>
      {children}
    </Provider>
  );
};
