import { Box } from "native-base";
import React, { useContext } from "react";

import { w24 } from "../../styles";
import { handleDummyFunction } from "../../utils";
import { TitledText } from "../text";
import { Spacer } from "../view";

interface IParameterCardProps {
  cardItem: IParameterItems;
  //   handleShowSampleDetail: () => void;
}

export const ParameterCard: React.FC<IParameterCardProps> = ({
  //   handleShowSampleDetail,
  cardItem,
}) => {
  //   reference: string
  //   productTypical: string
  //   previousResult: PreviousResult[]
  //   parameterMethod: string
  //   currentResult: CurrentResult
  //   parameterUnit: string
  //   specification: Specification
  //   parameterCategory: string
  //   parameterName: string

  const { productTypical, parameterName, parameterMethod, parameterUnit, specification } = cardItem;

  return (
    <div onClick={handleDummyFunction} style={{ cursor: "pointer" }}>
      <Box
        minHeight={"40"}
        px="8"
        py="4"
        paddingRight={"4"}
        borderRadius={4}
        mx="auto"
        shadow={4}
        // flexDirection={"row"}
        backgroundColor="white">
        <Box>
          <TitledText label={"Parameter Name"} item={parameterName} />
          <TitledText label={"Parameter Method"} item={parameterMethod} />
          <TitledText label={"Parameter Unit"} item={parameterUnit} />
          <TitledText label={"Product Typical"} item={productTypical} />
        </Box>

        <Spacer space={w24} />

        <Box>
          <TitledText label={"Min (Critical)"} item={specification.minCritical !== undefined ? specification.minCritical : "-"} />
          <TitledText label={"Min (Alarm)"} item={specification.minAlarm !== undefined ? specification.minAlarm : "-"} />
          <TitledText label={"Max (Critical)"} item={specification.maxCritical !== undefined ? specification.maxCritical : "-"} />
          <TitledText label={"Max (Alarm)"} item={specification.maxAlarm !== undefined ? specification.maxAlarm : "-"} />
        </Box>
      </Box>
    </div>
  );
};
