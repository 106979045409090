import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { Backdrop, Button, ButtonGroup, CircularProgress, FormControlLabel, FormGroup, Tab, Tabs } from "@mui/material";
import { Box, Checkbox, Heading, HStack, Image, Modal, Radio, ScrollView, Text, VStack } from "native-base";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BackIcon, ContentContainer, LabelledItemBoxed, Spacer } from "../../components";
import storage from "../../config/firebaseConfig";
import { DELETE_SAMPLE_BY_REF, GET_SAMPLE_BY_REF, UPDATE_SAMPLE_REPORT_BY_REF, UPDATE_SAMPLE_STATUS_BY_REF } from "../../constants";
import { GlobalContext, ISample } from "../../context";
import { h48, w4 } from "../../styles";
import { getLocalStorage } from "../../utils";
import { UploadReportComponent } from "./UploadReportComponent";

interface ISampleDetail {
  handleShowSampleDetail: (value: string | undefined) => void;
  selectedSampleRef: string | undefined;
  setErrorMessage: (value: string) => void;
  setError: (value: boolean) => void;
  error?: boolean;
}

export const SampleDetail = ({ handleShowSampleDetail, selectedSampleRef, setError, setErrorMessage, error }: ISampleDetail) => {
  const navigate = useNavigate();
  const { handleUpdateSelectedSampleRef } = useContext(GlobalContext);
  const [fetching, setFetching] = useState<boolean>(false);
  const [sampleData, setSampleData] = useState<ISample | undefined>();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteReport, setDeleteReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState<number[]>([]);
  const [reportUrl, setReportUrl] = useState<string | undefined>(undefined);
  const [sampleStatus, setSampleStatus] = useState<boolean>(sampleData !== undefined ? sampleData?.isCompleted : false);
  const [reportTabs, setReportTabs] = useState<number>(0);
  const isNotArrayOfReports = sampleData !== undefined && typeof sampleData.reportRef === "string";
  const reportArrayCondition = sampleData?.reportRef !== undefined && isNotArrayOfReports === false && sampleData?.reportRef.length > 0;
  const reportStringCondition = sampleData?.reportRef !== undefined && isNotArrayOfReports === true;

  // clientEmail: req.body.clientEmail,

  //     sampleId: req.body.sampleId,
  //     sampleName: req.body.sampleName,
  //     sampleType: req.body.sampleType,
  //     touchPoint: req.body.touchPoint,

  //     samplingDate: req.body.samplingDate,
  //     receivedDate: req.body.receivedDate,

  //     isCompleted: req.body.isCompleted,

  const handleCloseSampleDetail = () => {
    handleShowSampleDetail(undefined);
  };

  const handleGetSampleByRef = async () => {
    const request = {
      docReference: selectedSampleRef !== undefined ? selectedSampleRef : "",
    };

    try {
      const sampleByRefResponse = await fetch(GET_SAMPLE_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(request),
      });
      const sampleByRefContent = await sampleByRefResponse.json();

      // console.log("sampleByRefContent", sampleByRefContent.data);

      if (sampleByRefContent.data.message === "Success") {
        const samplesOfClient = sampleByRefContent.data.result.data;
        setSampleData(samplesOfClient);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET SPECIFIC SAMPLE DATA FOR ADMIN");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUpdateSampleStatusByRef = async () => {
    const request = {
      docReference: selectedSampleRef !== undefined ? selectedSampleRef : "",
      isCompleted: sampleStatus,
    };

    try {
      const sampleStatusResponse = await fetch(UPDATE_SAMPLE_STATUS_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(request),
      });
      const sampleStatusContent = await sampleStatusResponse.json();

      // console.log("sampleStatusContent", sampleStatusContent.data);

      if (sampleStatusContent.data.message === "Success") {
        // go back to ongoing tab
        handleCloseSampleDetail();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO UPDATE SAMPLE STATUS");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleDeleteSampleByRef = async () => {
    const deleteRequest = {
      docReference: selectedSampleRef !== undefined ? selectedSampleRef : "",
    };

    try {
      const deleteSampleResponse = await fetch(DELETE_SAMPLE_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(deleteRequest),
      });
      const deleteResponseContent = await deleteSampleResponse.json();

      // console.log("sampleStatusContent", sampleStatusContent.data);

      if (deleteResponseContent.data.message === "Success") {
        // go back to ongoing tab
        handleCloseSampleDetail();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE SAMPLE");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUpdateSampleReportLink = async (value: string[]) => {
    const request = {
      docReference: selectedSampleRef !== undefined ? selectedSampleRef : "",
      reportRef: value,
    };
    // console.log("request", request);

    try {
      const sampleReportResponse = await fetch(UPDATE_SAMPLE_REPORT_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(request),
      });
      const sampleReportContent = await sampleReportResponse.json();

      // console.log("sampleReportContent", sampleReportContent.data);

      if (sampleReportContent.data.message === "Success") {
        // redirect to dashboard ?
        handleCloseSampleDetail();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO UPDATE SAMPLE REPORT URL");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUploadPdf = async (uploadedFile: File) => {
    const newDate = new Date();
    const folderName = `${newDate.getFullYear()}/${newDate.getMonth()}`;
    const storageRef = ref(storage, `${folderName}/${uploadedFile.name}-${selectedSampleRef}.pdf`);
    const uploadTask = uploadBytesResumable(storageRef, uploadedFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // update progress
        // setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // console.log("url", url);
          setReportUrl(url);
          // return url;
        });
      },
    );
  };

  const handleCheckFileType = async () => {
    return file !== undefined && file.type === "application/pdf";
  };

  const handleSuccessModal = async () => {
    setFetching(true);
    await handleUpdateSampleStatusByRef();
    setFetching(false);
  };

  const handleSuccessSubmitReportModal = async () => {
    setFetching(true);
    // check file type to be application/pdf. if yes, will upload to storage
    const fileCheck = await handleCheckFileType();
    if (fileCheck === true && file !== undefined) {
      // upload pdf to storage, then get the response. If response successful, only then will update sample report link
      // console.log("file", file);
      await handleUploadPdf(file);
    } else {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: PLEASE UPLOAD A PDF FILE");
    }
    setFetching(false);
  };

  const handleSuccessDeleteSample = async () => {
    setFetching(true);
    await handleDeleteSampleByRef();
    // console.log("sampleData", sampleData);
    setFetching(false);
  };

  const handleSuccessDeleteReport = async () => {
    setFetching(true);
    if (selectedReport.length > 0) {
      const reportArray = sampleData !== undefined ? sampleData.reportRef : [];
      const updatedUrlArray: string[] = reportArray.filter((eachIndex, index) => selectedReport.indexOf(index) === -1);
      await handleUpdateSampleReportLink(updatedUrlArray);
      await handleGetSampleByRef();
    } else {
      setDeleteReport(false);
    }
    setFetching(false);
  };

  const handleChangeReportTab = (event: React.SyntheticEvent, newValue: number) => {
    setReportTabs(newValue);
  };

  const handleChangeSelectedReport = (isSelected: boolean, index: number) => {
    const updatedItem = selectedReport.indexOf(index);
    const updatedSelectedReport = [...selectedReport];
    if (isSelected === true) {
      updatedSelectedReport.push(index);
    } else {
      updatedSelectedReport.splice(updatedItem, 1);
    }

    setSelectedReport(updatedSelectedReport);
  };

  const handleUpdateSample = () => {
    handleUpdateSelectedSampleRef(selectedSampleRef !== undefined ? selectedSampleRef : "");
    navigate("/update-sample");
  };

  const content = (
    <div>
      <Box>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 12 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </div>
  );

  // console.log("sampleData", sampleData);

  useEffect(() => {
    handleGetSampleByRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportUrl !== undefined) {
      setTimeout(async () => {
        const reportRef = reportUrl !== undefined ? reportUrl : "";
        const isArray =
          sampleData !== undefined &&
          sampleData.reportRef !== undefined &&
          Object.prototype.toString.call(sampleData.reportRef).indexOf("Array") > -1;
        const reportArray = sampleData !== undefined ? sampleData.reportRef : [];
        const objectArray = sampleData !== undefined ? sampleData.reportRef : "";
        const updatedUrlArray: string[] = isArray ? reportArray : [`${objectArray}`];
        updatedUrlArray.push(reportRef);

        await handleUpdateSampleReportLink(updatedUrlArray);
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportUrl]);

  useEffect(() => {
    if (error !== undefined && error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <ContentContainer>
      <ScrollView flex={1} backgroundColor={"white"} borderRadius={4} shadow={"2"} padding={4}>
        {/* back icon component */}
        <Box>
          <BackIcon onPress={handleCloseSampleDetail} />
        </Box>

        {/* title component & picture */}
        <Box>
          <HStack space={4} alignItems={"center"} justifyContent={"center"}>
            <Heading size={"lg"}>Sample ID: {sampleData?.sampleId}</Heading>
            <Box width={8} height={8} borderRadius={20} backgroundColor={sampleData?.isCompleted ? "green.400" : "yellow.400"} />
          </HStack>
          {/* <Heading size={"lg"}>{selectedSampleRef}</Heading> */}

          <Spacer space={h48} />

          <Image
            alt="Logo of petrolab"
            source={require("../../assets/Logo Petrolab final.jpg")}
            style={{ width: 100, height: 100, position: "absolute", right: 12, top: -32 }}
          />
        </Box>

        {/* customer & sample information table  || interpretation table */}
        <VStack space={12}>
          {/* customer & sample information table */}
          <Box borderWidth={1}>
            <Box backgroundColor={"amber.200"} borderRadius={4} py={1}>
              <Heading size={"sm"}>Customer and Sample Information</Heading>
            </Box>
            <LabelledItemBoxed label={"Company Name:"} item={sampleData?.clientName} />
            <HStack>
              <Box flex={1} borderRightWidth={1}>
                {/* <LabelledItemBoxed label={"Batch"} item={sampleData?.batchNumber} /> */}
                <LabelledItemBoxed label={"Requestor"} item={sampleData?.clientRequestor} />
                <LabelledItemBoxed label={"Location:"} item={sampleData?.sampleLocation} />
                <LabelledItemBoxed label={"Sample Point:"} item={sampleData?.touchPoint} />
              </Box>

              <Box flex={1} borderRightWidth={1}>
                <LabelledItemBoxed label={"Sample Type:"} item={sampleData?.sampleType} />
                <LabelledItemBoxed label={"Sample Name:"} item={sampleData?.sampleName} />
                <LabelledItemBoxed label={"Sampling Date:"} item={sampleData?.samplingDate} />
                <LabelledItemBoxed label={"Received Date:"} item={sampleData?.receivedDate} />
              </Box>
            </HStack>
          </Box>

          {/* buttons to add, delete, update sample data */}
          <HStack space={w4} justifyContent={"space-around"}>
            <Button
              aria-label="button"
              variant={"outlined"}
              color={"info"}
              style={{ cursor: "pointer", flex: 1 }}
              onClick={() => setOpen(true)}>
              Update Status
            </Button>
            <Button
              aria-label="button"
              variant={"outlined"}
              color={"secondary"}
              style={{ cursor: "pointer", flex: 1 }}
              onClick={handleUpdateSample}>
              Update Sample
            </Button>
            <Button
              aria-label="button"
              variant={"outlined"}
              color={"success"}
              style={{ cursor: "pointer", flex: 1 }}
              onClick={() => setReportOpen(true)}>
              Add Report
            </Button>
            <Button
              aria-label="button"
              variant={"outlined"}
              color={"warning"}
              style={{ cursor: "pointer", flex: 1 }}
              onClick={() => setDeleteOpen(true)}>
              Delete Sample
            </Button>

            {sampleData !== undefined &&
            sampleData.reportRef !== undefined &&
            (sampleData?.reportRef.length > 0 || typeof sampleData.reportRef === "string") ? (
              <Button
                aria-label="button"
                variant={"outlined"}
                color={"inherit"}
                style={{ cursor: "pointer", flex: 1 }}
                onClick={() => setDeleteReport(true)}>
                Delete Report
              </Button>
            ) : null}
          </HStack>

          {sampleData?.reportRef !== undefined && isNotArrayOfReports === false && sampleData?.reportRef.length > 0 ? (
            <div>
              <Box borderBottomWidth={1} borderColor={"divider"}>
                <Tabs value={reportTabs} onChange={handleChangeReportTab} aria-label="basic tabs example">
                  {sampleData?.reportRef.map((eachtab, index) => {
                    return <Tab key={index} label={`Report ${index + 1}`} />;
                  })}
                </Tabs>
              </Box>

              {sampleData.reportRef.map((eachReport, index) => {
                return (
                  <div key={index} hidden={index !== reportTabs}>
                    <object data={eachReport} type="application/pdf" width="100%" height={window.innerHeight}>
                      <p>
                        Alternative text - include a link <a href={eachReport}>to the PDF!</a>
                      </p>
                    </object>
                  </div>
                );
              })}
            </div>
          ) : null}

          {sampleData?.reportRef !== undefined && isNotArrayOfReports === true ? (
            <div>
              <object data={sampleData.reportRef as unknown as string} type="application/pdf" width="100%" height={window.innerHeight}>
                <p>
                  Alternative text - include a link <a href={sampleData.reportRef as unknown as string}>to the PDF!</a>
                </p>
              </object>
            </div>
          ) : null}
        </VStack>
      </ScrollView>

      {/* modal to update sample status */}
      <Modal isOpen={open} onClose={() => setOpen(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Update sample status</Modal.Header>
          <Modal.Body>
            <Radio.Group
              name="For Client ?"
              value={sampleStatus === true ? "2" : "1"}
              onChange={(value) => {
                setSampleStatus(value === "1" ? false : true);
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Pending
                </Radio>
                <Radio value="2" my="2">
                  Completed
                </Radio>
              </HStack>
            </Radio.Group>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                disabled={fetching === true}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to upload report */}
      <Modal isOpen={reportOpen} onClose={() => setReportOpen(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Upload Sample Report</Modal.Header>
          <Modal.Body>
            <UploadReportComponent file={file} setFile={setFile} />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setReportOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessSubmitReportModal}>
                Submit report
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to delete sample */}
      <Modal isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Delete the sample ?</Modal.Header>
          <Modal.Body>
            <Text>Are you sure you want to delete sample</Text>
            <Text fontWeight={"bold"}>{sampleData?.sampleId}</Text>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessDeleteSample}>
                Confirm Delete
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to delete report */}
      <Modal isOpen={deleteReport} onClose={() => setDeleteReport(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Delete report ?</Modal.Header>
          <Modal.Body>
            <Text>Which report to delete ?</Text>
            <FormGroup>
              {reportArrayCondition
                ? sampleData.reportRef.map((eachReport, index) => {
                    return (
                      <FormControlLabel
                        control={<Checkbox value={`Report ${index + 1}`} onChange={(value) => handleChangeSelectedReport(value, index)} />}
                        label={`Report ${index + 1}`}
                      />
                    );
                  })
                : null}

              {reportStringCondition ? (
                <FormControlLabel
                  control={<Checkbox value={`Report`} onChange={(value) => handleChangeSelectedReport(value, 0)} />}
                  label="Label"
                />
              ) : null}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteReport(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessDeleteReport}>
                Confirm Delete
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>
    </ContentContainer>
  );
};
