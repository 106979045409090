import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, HStack } from "native-base";
import React from "react";

import { w2 } from "../../styles";
import { handleDummyFunction } from "../../utils";
import { ListingText } from "../text";

interface IClientCardProps {
  cardItem: IClientItems;
  //   handleShowSampleDetail: () => void;

  setDeleteOpen: (value: boolean) => void;
  setEditOpen: (value: boolean) => void;
  setSelectedClient: (value: IClientItems) => void;
}

export const ClientCard: React.FC<IClientCardProps> = ({
  //   handleShowSampleDetail,
  cardItem,
  setDeleteOpen,
  setEditOpen,
  setSelectedClient,
}) => {
  const { clientEmail, clientAddress, clientName, clientId } = cardItem;

  const handleDeleteAction = () => {
    setDeleteOpen(true);
    setSelectedClient(cardItem);
  };

  const handleEditAction = () => {
    setEditOpen(true);
    setSelectedClient(cardItem);
  };

  return (
    <div onClick={handleDummyFunction} style={{ cursor: "pointer" }}>
      <Box px="2" py="2" paddingRight={"4"} borderRadius={4} mx="auto" backgroundColor="white" width={window.innerWidth - 250}>
        <HStack space={4}>
          {/* <ListingText label={reference} /> */}
          <ListingText label={`${clientId || "-"}`} flexValue={0.5} />
          <ListingText label={clientName} flexValue={2} />
          <ListingText label={clientEmail} />
          <ListingText label={clientAddress} />

          <HStack space={w2} flex={0.4} justifyContent={"flex-start"}>
            <DeleteIcon
              onClick={() => {
                handleDeleteAction();
              }}
            />
            <EditIcon
              onClick={() => {
                handleEditAction();
              }}
            />
          </HStack>
        </HStack>
      </Box>
    </div>
  );
};
