import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, TextField } from "@mui/material";
import React, { Fragment, useContext } from "react";

import { Spacer } from "../../components";
import { GlobalContext, ISearchDashboard, TByCategory } from "../../context";
import { w200, w8 } from "../../styles";

interface ISearchDashboardComponent {
  onPress: (searchDashboard: ISearchDashboard) => void;
}

export const SearchDashboard = ({ onPress }: ISearchDashboardComponent) => {
  const { globalState, handleUpdateSearchDashboard } = useContext(GlobalContext);
  const { searchDashboard } = globalState;
  const { categoryItems, searchText, byCategory } = searchDashboard;

  const handleUpdateSearch = (value: string) => {
    handleUpdateSearchDashboard({ ...searchDashboard, searchText: value });
  };

  const handleUpdateByCategory = (value: TByCategory) => {
    handleUpdateSearchDashboard({ ...searchDashboard, byCategory: value });
  };

  const handleSearch = () => {
    // if (searchText !== "") {
    // }
    onPress(searchDashboard);
  };

  const handleClearSearch = () => {
    const resetSearchDashboard: ISearchDashboard = {
      searchText: "",
      byCategory: "sampleId",
      categoryItems: ["sampleId"],
    };
    // handleResetSearchDashboard();
    onPress(resetSearchDashboard);
  };

  return (
    <div>
      <FormControl
        fullWidth
        sx={{ m: 1 }}
        style={{
          backgroundColor: "white",
          flexDirection: "row",
        }}>
        <InputLabel htmlFor="outlined-search-icon" size={"small"}>
          Search
        </InputLabel>
        <OutlinedInput
          id="outlined-search-input"
          size={"small"}
          onChange={(e) => handleUpdateSearch(e.target.value)}
          style={{ flex: 1 }}
          label="search"
          value={searchText}
          endAdornment={
            <InputAdornment position="end">
              {searchText !== "" ? (
                <Fragment>
                  <IconButton aria-label="highlight-off-icon-button" edge="end" onClick={handleClearSearch} size={"small"}>
                    {<HighlightOffIcon />}
                  </IconButton>
                </Fragment>
              ) : null}

              <IconButton aria-label="search-icon-button" edge="end" onClick={handleSearch} size={"medium"}>
                {<SearchIcon />}
              </IconButton>
            </InputAdornment>
          }
        />

        <Spacer space={w8} isHorizontal={true} />

        <TextField
          id="outlined-select-currency"
          select
          size={"small"}
          label="Search By"
          value={byCategory}
          style={{ width: w200 }}
          //   helperText="Please select your currency"
        >
          {categoryItems.map((option) => {
            const checkClientName = option === "clientName" ? "company name" : "sample name";
            const checkOptionNames = option === "sampleId" ? "sample id" : checkClientName;
            return (
              <MenuItem key={option} value={option} onClick={() => handleUpdateByCategory(option)}>
                {checkOptionNames}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    </div>
  );
};
