// todo - update all cw in the projects to be imported from here. No hard coded CW / text

export const HEADER = {};

export const SUB_HEADER = {};

export const LOGIN = {
  BUTTON_LOGIN: "Sign in with Email",
  BUTTON_SIGNUP: "Sign up with Email",
  LABEL_LOGIN: "Sign In",
  LABEL_SIGNUP: "Sign Up",
  PLACEHOLDER_EMAIL: "Email",
  PLACEHOLDER_MATCH_PASSWORD: "Repeat Password",
  PLACEHOLDER_PASSWORD: "Password",
};

export const ENQUIRY = {
  LABEL_TITLE: "Title",
  LABEL_TYPE: "Type",
  LABEL_CATEGORY: "Category",
  LABEL_DETAIL: "Detail",
  LABEL_CONTACT: "Contact",
  LABEL_LOCATION: "Location",
  LABEL_ATTACHMENT: "Attachment",
};

export const ERROR_MESSAGE = {
  BLOCKED_ACCESS: "You don't have access to the app. Please contact PTGM support",
  INPUT_CREDENTIAL: "Please input email & password.",
  NOT_REGISTERED: "You are not registered !",
  QUERY_FAILED: "Your query failed. Please contact support.",
  NOT_MATCHING_PASSWORD: "Password not matched.",
};

export const DISCLAIMER_MESSAGE = [
  "Disclaimer :",
  "1. All tests have been performed using the latest revision of the method indicated, unless specifically marked otherwise on the report.",
  "2. User of the data shown on this report should refer to the latest published revisions of ASTM D 3244, IP 367 and IP Standards (Test Methods) Appendix E for utilization of test",
  "   data to determine conformance with specification.",
  "3. The test result relate only to the items tested.",
  "4. This Analytical Report / Certificate of Quality will not be reproduced except in full, without the written approval of RFL Petrolab Sdn. Bhd.",
];

// "Disclaimer :\n1. All tests have been performed using the latest revision of the method indicated, unless specifically marked otherwise on the report.\n2 .User of the data shown on this report should refer to the latest published revisions of ASTM D 3244, IP 367 and IP Standards (Test Methods) Appendix E for utilization of test data to determine conformance with specification.\n3. The test result relate only to the items tested.\n4. This Analytical Report / Certificate of Quality will not be reproduced except in full, without the written approval of RFL Petrolab Sdn. Bhd.";
