import { Box, FormControl, Input } from "native-base";
import React, { Fragment, useEffect, useReducer } from "react";

import { Spacer } from "../../components";
import { w16 } from "../../styles";

interface INewParameter {
  parameterData: Omit<IParameterItems, "reference">;
  isSubmitted: boolean;
}

const initialNewParam: INewParameter = {
  isSubmitted: false,
  parameterData: {
    parameterCategory: "",
    parameterName: "",
    parameterMethod: "",
    parameterUnit: "",
    specification: {
      minCritical: "",
      minAlarm: "",
      maxCritical: "",
      maxAlarm: "",
    },
    productTypical: "",
    currentResult: {
      date: "-",
      result: "-",
    },
    previousResult: [
      {
        date: "-",
        result: "-",
      },
      {
        date: "-",
        result: "-",
      },
      {
        date: "-",
        result: "-",
      },
      {
        date: "-",
        result: "-",
      },
    ],
  },
};

const reducer = (state: INewParameter, action: { type: string; item: any }) => {
  switch (action.type) {
    case "add_item":
      return {
        ...state,
        parameterData: { ...state.parameterData, ...action.item },
      };
    case "add_specification":
      return {
        ...state,
        parameterData: {
          ...state.parameterData,
          specification: {
            ...action.item,
          },
        },
      };
    case "run_query":
      return { ...state, isSubmitted: action.item };
    case "reset":
      return { ...initialNewParam };
    default:
      return state;
  }
};

interface IAddNewParameter {
  setParameterRequest: (request: Omit<IParameterItems, "reference">) => void;
}

export const AddNewParameter = ({ setParameterRequest }: IAddNewParameter) => {
  const [state, dispatch] = useReducer(reducer, initialNewParam);

  const handleUpdateCategory = (value: string) => {
    dispatch({ type: "add_item", item: { parameterCategory: value } });
  };
  const handleUpdateName = (value: string) => {
    dispatch({ type: "add_item", item: { parameterName: value } });
  };
  const handleUpdateMethod = (value: string) => {
    dispatch({ type: "add_item", item: { parameterMethod: value } });
  };
  const handleUpdateUnit = (value: string) => {
    dispatch({ type: "add_item", item: { parameterUnit: value } });
  };
  const handleUpdateProductTypical = (value: string) => {
    dispatch({ type: "add_item", item: { productTypical: value } });
  };
  const handleUpdateSpecMinCritical = (value: string) => {
    dispatch({
      type: "add_specification",
      item: { ...state.parameterData.specification, minCritical: value },
    });
  };
  const handleUpdateSpecMinAlarm = (value: string) => {
    dispatch({
      type: "add_specification",
      item: { ...state.parameterData.specification, minAlarm: value },
    });
  };
  const handleUpdateSpecMaxCritical = (value: string) => {
    dispatch({
      type: "add_specification",
      item: { ...state.parameterData.specification, maxCritical: value },
    });
  };
  const handleUpdateSpecMaxAlarm = (value: string) => {
    dispatch({
      type: "add_specification",
      item: { ...state.parameterData.specification, maxAlarm: value },
    });
  };

  const handleUpdateRequest = () => {
    // !! what happen here ??

    const request = {
      ...state.parameterData,
      currentResult: {
        date: "-",
        result: "-",
      },
      previousResult: [
        {
          date: "-",
          result: "-",
        },
        {
          date: "-",
          result: "-",
        },
        {
          date: "-",
          result: "-",
        },
        {
          date: "-",
          result: "-",
        },
      ],
    };
    setParameterRequest(request);
  };

  useEffect(() => {
    handleUpdateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Box paddingBottom={4}>
      <FormControl>
        <FormControl.Label>Category</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateCategory}
          placeholder={"category"}
          value={state.parameterData.parameterCategory}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
        <FormControl.Label>Name</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateName}
          placeholder={"name"}
          value={state.parameterData.parameterName}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
        <FormControl.Label>Method</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateMethod}
          placeholder={"method"}
          value={state.parameterData.parameterMethod}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />

        <FormControl.Label>Unit</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateUnit}
          placeholder={"unit"}
          value={state.parameterData.parameterUnit}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />

        <FormControl.Label>Product Typical</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateProductTypical}
          placeholder={"product typical"}
          value={state.parameterData.productTypical}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />

        <Box flexDirection={"row"}>
          <Box>
            <FormControl.Label>Min Critical</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSpecMinCritical}
              placeholder={"min critical"}
              value={state.parameterData.specification.minCritical}
              variant="outline"
              keyboardType="numeric"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 100,
              }}
            />
          </Box>

          <Spacer space={24} isHorizontal={true} />

          <Box>
            <FormControl.Label>Min Alarm</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSpecMinAlarm}
              placeholder={"min alarm"}
              value={state.parameterData.specification.minAlarm}
              variant="outline"
              keyboardType="numeric"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 100,
              }}
            />
          </Box>

          <Spacer space={24} isHorizontal={true} />

          <Box>
            <FormControl.Label>Max Critical</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSpecMaxCritical}
              placeholder={"max critical"}
              value={state.parameterData.specification.maxCritical}
              variant="outline"
              keyboardType="numeric"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 100,
              }}
            />
          </Box>

          <Spacer space={24} isHorizontal={true} />

          <Box>
            <FormControl.Label>Max Alarm</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSpecMaxAlarm}
              placeholder={"max critical"}
              value={state.parameterData.specification.maxAlarm}
              variant="outline"
              keyboardType="numeric"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 100,
              }}
            />
          </Box>
        </Box>
      </FormControl>

      {/* view show loading */}
      {/* {state.isSubmitted ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null} */}
    </Box>
  );
};
