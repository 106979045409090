import { colorBlue } from "./colors";
import { autoHeight, autoWidth } from "./scaler";

export const heightValue: number = window.innerHeight;
export const widthValue: number = window.innerWidth;
export const isWindow = widthValue > 320;

export const deviceHeight = { height: window.innerHeight } as const;
export const deviceWidth = { width: window.innerWidth } as const;
export const deviceMaxHeight = { maxHeight: window.innerHeight } as const;

export const flexContainer = { display: "flex" } as const;
export const flexCol = { ...flexContainer, flexDirection: "column" } as const;
export const flexGrow = { flexGrow: 1 } as const;
export const flexOne = { flex: 1 } as const;
export const flexRow = { ...flexContainer, flexDirection: "row" } as const;
export const flexWrap = { ...flexContainer, flexWrap: "wrap" } as const;

export const justifyCenter = { justifyContent: "center" } as const;
export const justifyFlexEnd = { justifyContent: "flex-end" } as const;
export const justifyFlexStart = { justifyContent: "flex-start" } as const;
export const justifySpaceAround = { justifyContent: "space-around" } as const;
export const justifySpaceBetween = { justifyContent: "space-between" } as const;

export const alignItemCenter = { alignItems: "center" } as const;

export const h1: number = autoHeight(1);
export const h2: number = autoHeight(2);
export const h4: number = autoHeight(4);
export const h8: number = autoHeight(8);
export const h12: number = autoHeight(12);
export const h16: number = autoHeight(16);
export const h24: number = autoHeight(24);
export const h32: number = autoHeight(32);
export const h36: number = autoHeight(36);
export const h48: number = autoHeight(48);
export const h56: number = autoHeight(56);
export const h80: number = autoHeight(80);
export const h125: number = autoHeight(125);
export const h128: number = autoHeight(128);

export const w1: number = autoWidth(1);
export const w2: number = autoWidth(2);
export const w4: number = autoWidth(4);
export const w8: number = autoWidth(8);
export const w12: number = autoWidth(12);
export const w16: number = autoWidth(16);
export const w24: number = autoWidth(24);
export const w32: number = autoWidth(32);
export const w48: number = autoWidth(48);
export const w56: number = autoWidth(56);
export const w100: number = autoWidth(100);
export const w140: number = autoWidth(140);
export const w200: number = autoWidth(200);
export const w280: number = autoWidth(280);
export const w480: number = autoWidth(480);

export const padding8 = { padding: h16 } as const;
export const padding16 = { padding: h16 } as const;
export const padding32 = { padding: h16 } as const;

export const paddingTop32 = { paddingTop: h32 } as const;

export const paddingHorizontal8 = { paddingHorizontal: h8 } as const;
export const paddingHorizontal16 = { paddingHorizontal: h16 } as const;
export const paddingHorizontal32 = { paddingHorizontal: h32 } as const;
export const paddingHorizontal48 = { paddingHorizontal: h48 } as const;
export const paddingHorizontal56 = { paddingHorizontal: h56 } as const;

export const paddingVertical16 = { paddingVertical: h16 } as const;
export const paddingVertical32 = { paddingVertical: h32 } as const;
export const paddingVertical48 = { paddingVertical: h48 } as const;
export const paddingVertical56 = { paddingVertical: h56 } as const;

export const shadowBlue1 = {
  // shadow for android
  //   elevation: 1,
  // shadow for ios
  shadowColor: colorBlue._1,
  shadowOffset: {
    width: 2,
    height: 2,
  },
  shadowOpacity: 0.24,
  shadowRadius: 4,
} as const;
