import { Box, HStack } from "native-base";
import React from "react";

import { ISample } from "../../context";
import { ListingText } from "../text";

interface ISampleCardProps {
  cardItem: ISample;
  isCompleted: boolean;
  handleShowSampleDetail: (value: string) => void;
}

export const SampleCard: React.FC<ISampleCardProps> = ({ isCompleted = false, handleShowSampleDetail, cardItem }) => {
  // isPackageTest: boolean;
  // isFresh: boolean;
  // receivedDate: string;
  // clientId: string;
  // poReference: string;
  // testingStartDate: string;
  // testingFinishDate: string;
  // testComment: string;
  // reportedDate: string;
  // sampleAge: string;
  // sampleName: string;
  // sampleId: string;
  // equipmentUnitId: string;
  // isCompleted: boolean;
  // sampleType: string;
  // samplingDate: string;
  // sampleResult: ISampleResult[];

  const { reference, sampleId, receivedDate, samplingDate, sampleName, sampleType, clientRequestor, clientName, batchNumber } = cardItem;

  const handleSelectSample = () => {
    handleShowSampleDetail(reference);
  };

  return (
    <div onClick={handleSelectSample} style={{ cursor: "pointer" }}>
      <Box px="2" py="2" mx="auto" width={window.innerWidth - 250}>
        <HStack
          space={4}
          // justifyContent="space-between"
          alignItems="center"
          // divider={<div style={{ borderWidth: 20, borderColor: "Highlight" }} />}
          // justifyContent="space-between"
        >
          <ListingText label={sampleId} flexValue={0.5} />
          <ListingText label={clientName} flexValue={2} />
          <ListingText label={sampleName} flexValue={0.5} />
          <ListingText label={samplingDate} flexValue={0.5} />
          <ListingText label={receivedDate} flexValue={0.5} />
          <ListingText label={sampleType} flexValue={0.5} />
          <ListingText label={clientRequestor} flexValue={0.5} />
          <ListingText label={isCompleted ? "Completed" : "In Progress"} flexValue={0.5} textColor={isCompleted ? "green" : "orange"} />
        </HStack>
      </Box>
    </div>
  );
};
