import { Box, ScrollView, VStack } from "native-base";
import React, { Fragment } from "react";

import { PermissionCard } from "../../components";
import { h8 } from "../../styles";
import { IPermissionItems } from "./DashboardPermission";

interface IListingPermission {
  permissions: IPermissionItems[];
  setDeleteOpen: (value: boolean) => void;
  setEditOpen: (value: boolean) => void;
  setSelectedPermission: (value: IPermissionItems) => void;
}

export const ListingPermission = ({ permissions, setSelectedPermission, setDeleteOpen, setEditOpen }: IListingPermission) => {
  return (
    <Fragment>
      <ScrollView>
        {/* show completed item */}
        <Box height={4} />

        <VStack space="4">
          {permissions.sort().map((eachPermission, index) => {
            return (
              <Fragment key={`${index}${eachPermission.reference}`}>
                <PermissionCard
                  // {...sampleCardProps}
                  cardItem={eachPermission}
                  setDeleteOpen={setDeleteOpen}
                  setSelectedPermission={setSelectedPermission}
                  setEditOpen={setEditOpen}
                />
                {index < permissions.length - 1 ? <Box height={1} borderTopWidth={1} opacity={0.3} marginX={h8} /> : null}
              </Fragment>
            );
          })}
        </VStack>
      </ScrollView>
    </Fragment>
  );
};
