import { Box, FormControl, HStack, Input, Radio, View } from "native-base";
import React, { useEffect, useReducer } from "react";

import { h8, w16 } from "../../styles";
import { IPermissionItems } from "./DashboardPermission";
import { MenuItem, TextField } from "@mui/material";
import { Spacer } from "../../components";

interface INewPermission {
  parameterData: Omit<IPermissionItems, "reference">;
  isSubmitted: boolean;
}

const initialNewPermission: INewPermission = {
  isSubmitted: false,
  parameterData: {
    clientEmail: "",
    clientId: "",
    isAllowed: true,
    isClient: true,
    adminPermissionLevel: "1",
  },
};

const reducer = (state: INewPermission, action: { type: string; item: any }) => {
  switch (action.type) {
    case "add_item":
      return {
        ...state,
        parameterData: { ...state.parameterData, ...action.item },
      };
    case "run_query":
      return { ...state, isSubmitted: action.item };
    case "reset":
      return { ...initialNewPermission };
    default:
      return state;
  }
};

interface IAddNewPermission {
  setPermissionRequest: (request: Omit<IPermissionItems, "reference">) => void;
  isNewPermission?: boolean;
  selectedPermission?: IPermissionItems;
  existingClients: IClientItems[];
}

export const AddNewPermission = ({
  setPermissionRequest,
  isNewPermission = true,
  selectedPermission,
  existingClients,
}: IAddNewPermission) => {
  const theSelectedPermission = { ...selectedPermission };
  delete theSelectedPermission.reference;
  const [state, dispatch] = useReducer(reducer, initialNewPermission);

  const handleUpdateClientEmail = (value: string) => {
    dispatch({ type: "add_item", item: { clientEmail: value } });
  };
  const handleUpdateIsClient = (value: boolean) => {
    dispatch({ type: "add_item", item: { isClient: value } });
  };
  const handleUpdateClientId = (value: string) => {
    dispatch({ type: "add_item", item: { clientId: value } });
  };
  const handleUpdateIsAllowed = (value: boolean) => {
    dispatch({ type: "add_item", item: { isAllowed: value } });
  };
  const handleUpdateAdminPermissionLevel = (value: string) => {
    dispatch({ type: "add_item", item: { adminPermissionLevel: value } });
  };

  const handleUpdateRequest = () => {
    const request = {
      ...state.parameterData,
    };
    // console.log("request", request);
    setPermissionRequest(request);
  };

  useEffect(() => {
    handleUpdateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (isNewPermission === false && selectedPermission !== undefined) {
      handleUpdateClientEmail(selectedPermission.clientEmail);
      // console.log("asdn aksnbd");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box paddingBottom={4}>
      <FormControl>
        <FormControl.Label>Select Client *</FormControl.Label>
        <TextField
          // id="outlined-select-currency"
          select
          size={"small"}
          label="Select Existing Client +"
          // value={}
          style={{ width: 300 }}
          // style={{ width: "100%" }}
          // placeholder="New +"
          // helperText="Add New"
        >
          {existingClients
            .filter((eachClient) => eachClient.clientId !== undefined && eachClient.clientId !== "")
            .map((option) => {
              const { clientId, clientName, clientAddress, clientEmail } = option;

              const handleUpdateExistingClient = () => {
                handleUpdateClientEmail(clientEmail);
                handleUpdateClientId(clientId);
              };

              return (
                <MenuItem key={clientEmail} value={clientEmail} onClick={handleUpdateExistingClient}>
                  {clientName} - {clientEmail}
                </MenuItem>
              );
            })}
        </TextField>

        <FormControl.Label>Client Email *</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateClientEmail}
          placeholder={"email"}
          value={state.parameterData.clientEmail}
          isDisabled={true}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
      </FormControl>

      <FormControl>
        <FormControl.Label>Client Id *</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateClientId}
          placeholder={"client id"}
          value={state.parameterData.clientId}
          isDisabled={true}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
      </FormControl>

      <Spacer space={h8} />
      <HStack space={w16}>
        <View>
          <FormControl.Label
            _text={{
              fontSize: "sm",
              bold: true,
            }}>
            For Client ?
          </FormControl.Label>
          <Radio.Group
            name="For Client ?"
            value={state.parameterData.isClient ? "1" : "2"}
            onChange={(value) => {
              handleUpdateIsClient(value === "1" ? true : false);
            }}>
            <HStack space={4} alignItems={"center"}>
              <Radio value="1" my="1">
                Yes
              </Radio>
              <Radio value="2" my="2">
                No
              </Radio>
            </HStack>
          </Radio.Group>
        </View>

        {!state.parameterData.isClient ? (
          <View>
            <FormControl.Label
              _text={{
                fontSize: "sm",
                bold: true,
              }}>
              Permission Level ?
            </FormControl.Label>
            <Radio.Group
              name="Permission Level ?"
              value={state.parameterData.adminPermissionLevel}
              onChange={(value) => {
                handleUpdateAdminPermissionLevel(value);
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Level 1
                </Radio>
                <Radio value="2" my="2">
                  Level 2
                </Radio>
              </HStack>
            </Radio.Group>
          </View>
        ) : null}
      </HStack>

      <FormControl.Label
        _text={{
          fontSize: "sm",
          bold: true,
        }}>
        Is Allowed ?
      </FormControl.Label>
      <Radio.Group
        name="Is Allowed ?"
        value={state.parameterData.isAllowed ? "1" : "2"}
        onChange={(value) => {
          handleUpdateIsAllowed(value === "1" ? true : false);
        }}>
        <HStack space={4} alignItems={"center"}>
          <Radio value="1" my="1">
            Yes
          </Radio>
          <Radio value="2" my="1">
            No
          </Radio>
        </HStack>
      </Radio.Group>

      {/* view show loading */}
      {/* {state.isSubmitted ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null} */}
    </Box>
  );
};
