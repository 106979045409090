import { Button, ButtonGroup, Fab } from "@mui/material";
import { Box, Modal } from "native-base";
import React, { Fragment, useEffect, useState } from "react";

import { ContentContainer, CustomAlert } from "../../components";
import { ADD_NEW_PARAMETERS, GET_ALL_PARAMETERS } from "../../constants";
import { h24, h8, w24, w8 } from "../../styles";
import { getLocalStorage } from "../../utils";
import { AddNewParameter } from "./AddNewParameter";
import { ListingParameter } from "./ListingParameter";

export const DashboardParameter = () => {
  const [parameterItems, setParameterItems] = useState<IParameterItems[] | []>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [parameterRequest, setParameterRequest] = useState<Omit<IParameterItems, "reference"> | undefined>(undefined);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleFetchAllParameters = async () => {
    try {
      const parameterResponse = await fetch(GET_ALL_PARAMETERS, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
      });
      const parameterContent = await parameterResponse.json();

      if (parameterContent.data.message === "Success") {
        const parameters = parameterContent.data.result.parameters;
        setParameterItems(parameters);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET CLIENT PROFILE");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleSetNewParameter = async () => {
    try {
      const parameterResponse = await fetch(ADD_NEW_PARAMETERS, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(parameterRequest),
      });
      const parameterContent = await parameterResponse.json();

      // console.log("parameterContent", parameterContent.data);

      if (parameterContent.data.message === "Success") {
        setOpen(false);
        setParameterRequest(undefined);
      } else {
        // console.log("failed to add new parameter");
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW PARAMETER");
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleSuccessModal = async () => {
    await handleSetNewParameter();
    await handleFetchAllParameters();
  };

  useEffect(() => {
    handleFetchAllParameters();
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <ContentContainer>
      <Fragment>
        <ListingParameter parameters={parameterItems} />
      </Fragment>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* fab button to open modal */}
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        onClick={handleOpenModal}
        style={{ position: "absolute", right: w24, bottom: h24 }}>
        Add new parameter
      </Fab>

      {/* modal to add new parameter */}
      <Modal isOpen={open} onClose={() => setOpen(false)} safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Add New Parameter</Modal.Header>
          <Modal.Body>
            <AddNewParameter setParameterRequest={setParameterRequest} />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </ContentContainer>
  );
};
