import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { Box as MUIBox, Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Box, Divider, Heading, Image, Text } from "native-base";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../context";
import { h2, h4, h8 } from "../../styles";
import { getLocalStorage, useWindowDimensions } from "../../utils";

interface ISideMenuNew {
  handleCloseDetailPage?: () => void;
}

export const SideMenuNew = ({ handleCloseDetailPage }: ISideMenuNew) => {
  const navigate = useNavigate();
  // const navigation = useNavigation<IStackNavigationProp>();
  const { globalState, handleUpdateSelectedMenu, handleResetGlobal } = useContext(GlobalContext);
  const { selectedMenu, adminEmail } = globalState;
  const { width } = useWindowDimensions();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const isLowerLevelAdmin = ["Analysis"];
  const isHigherLevelAdmin = ["Analysis", "Client", "Permission"]; // "Parameter"
  const theMenuItems = getLocalStorage("admin-level") === "2" ? isHigherLevelAdmin : isLowerLevelAdmin;

  const handleUpdateMenu = (value: string) => {
    if (handleCloseDetailPage !== undefined) {
      handleCloseDetailPage();
    }
    handleUpdateSelectedMenu(value, true);
    // handleResetSearchDashboard();
  };
  const handleLogOut = async () => {
    await handleResetGlobal();
    navigate("/login");
    // navigation.navigate("Public");
  };
  const handleSelectMenu = (value: string) => {
    if (selectedMenu !== value) {
      handleUpdateMenu(value);
    }
  };

  const toggleDrawer = (anchor: any, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: any) => (
    <MUIBox
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      {/* logo & company name */}
      <Box alignItems={"start"} marginBottom={h8} padding={h4}>
        <Box
          // flexDirection={"row"}
          alignItems={"start"}
          justifyContent={"start"}>
          <Image
            size={40}
            alt="petrolab logo"
            borderRadius={100}
            source={require("../../assets/Logo Petrolab final.jpg")}
            fallbackSource={{
              uri: "https://www.w3schools.com/css/img_lights.jpg",
            }}
            marginBottom={h4}
          />

          <Box justifyContent={"start"} alignItems={"start"}>
            <Heading size={"sm"} fontWeight={300} opacity={0.8} marginBottom={h2}>
              Welcome,
            </Heading>
            <Box
              //  backgroundColor={"amber.200"}
              justifyContent={"start"}>
              <Heading size={"sm"}>admin PetroLab</Heading>
            </Box>
            <Heading size={"xs"} fontWeight={500} opacity={0.6}>
              {adminEmail}
            </Heading>
          </Box>
        </Box>

        {/* divider of title in sidemenu */}
        <Divider
          marginTop={4}
          my="2"
          thickness={"4"}
          _light={{
            bg: "lightBlue.200",
          }}
        />
      </Box>
      <List>
        {theMenuItems.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                handleSelectMenu(text);
              }}>
              <ListItemIcon>{text === "Analysis" ? <ScatterPlotIcon /> : <AccountBoxIcon />}</ListItemIcon>
              <ListItemText
                primary={text}
                style={{
                  fontWeight: selectedMenu === text ? "bold" : "normal",
                  opacity: selectedMenu === text ? 1 : 0.5,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Log Out"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={handleLogOut}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </MUIBox>
  );

  // console.log("window.innerWidth", window.innerWidth);

  return (
    <div>
      {width > 360 ? (
        <div className="class-menu-background">
          <Box
            width={210}
            height={window.innerHeight}
            maxHeight={window.innerHeight}
            paddingX={4}
            paddingTop={8}
            backgroundColor={"#31304D"}
            shadow={8}>
            {/* logo & company name */}
            <Box alignItems={"start"} marginBottom={h8}>
              <Box
                // flexDirection={"row"}
                alignItems={"start"}
                justifyContent={"start"}>
                <Image
                  size={40}
                  alt="petrolab logo"
                  borderRadius={100}
                  source={require("../../assets/Logo Petrolab final.jpg")}
                  fallbackSource={{
                    uri: "https://www.w3schools.com/css/img_lights.jpg",
                  }}
                  marginBottom={h4}
                />

                <Box justifyContent={"start"} alignItems={"start"}>
                  <Heading size={"sm"} fontWeight={300} color={"cyan.50"} opacity={0.8} marginBottom={h2}>
                    Welcome,
                  </Heading>
                  <Box
                    //  backgroundColor={"amber.200"}
                    justifyContent={"start"}>
                    <Heading color={"cyan.50"} size={"sm"}>
                      admin PetroLab
                    </Heading>
                  </Box>
                  <Heading color={"cyan.50"} size={"xs"} fontWeight={500} opacity={0.6}>
                    {adminEmail}
                  </Heading>
                </Box>
              </Box>

              {/* divider of title in sidemenu */}
              <Divider
                marginTop={4}
                my="2"
                thickness={"4"}
                _light={{
                  bg: "lightBlue.200",
                }}
              />
            </Box>

            <List>
              {theMenuItems.map((text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleSelectMenu(text);
                    }}>
                    <ListItemIcon style={{ color: "white" }}>{text === "Analysis" ? <ScatterPlotIcon /> : <AccountBoxIcon />}</ListItemIcon>
                    <ListItemText
                      primary={text}
                      style={{
                        color: "white",
                        fontWeight: selectedMenu === text ? "bold" : "normal",
                        opacity: selectedMenu === text ? 1 : 0.5,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <div onClick={handleLogOut} style={{ cursor: "pointer" }}>
              <Box
                position={"absolute"}
                bottom={24}
                alignItems={"center"}
                justifyContent={"center"}
                borderWidth={"1"}
                borderRadius={4}
                width={180}
                borderColor={"cyan.50"}
                // onPress={handleLogOut}
                // flex={1}
                py={2}
                opacity={0.8}>
                <Text color={"cyan.50"}>Log out</Text>
              </Box>
            </div>
          </Box>
        </div>
      ) : (
        <div style={{ width: "auto" }}>
          {(["left"] as const).map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)}>
                <MenuIcon />
              </Button>
              <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
