import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Box, Modal, Text } from "native-base";
import React, { useEffect, useRef, useState } from "react";

import { ContentContainer, CustomAlert, ListingTitleClient, Spacer } from "../../components";
import {
  ADD_NEW_CLIENTS,
  DELETE_CLIENT_BY_REF,
  DELETE_PERMISSION_BY_REF,
  GET_ALL_CLIENTS,
  GET_PERMISSION_BY_EMAIL,
  UPDATE_CLIENT_BY_REF,
} from "../../constants";
import { h12, h4, h48, h8, w8 } from "../../styles";
import { getLocalStorage } from "../../utils";
import { AddNewClient } from "./AddNewClient";
import { ClientListingParameter } from "./ClientListingParameter";
import { UpdateClient } from "./UpdateClient";

export const DashboardClient = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [clientItems, setClientItems] = useState<IClientItems[] | []>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<IClientItems | undefined>(undefined);
  const [clientRequest, setClientRequest] = useState<Omit<IClientItems, "reference"> | undefined>(undefined);
  const networkActionRef = useRef<boolean>(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleFetchAllClients = async () => {
    try {
      const clientResponse = await fetch(GET_ALL_CLIENTS, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
      });
      const clientContent = await clientResponse.json();
      //   console.log("clientContent", clientContent.data);

      if (clientContent.data.message === "Success") {
        const clientsData = clientContent.data.result.data;
        const sortedClients = clientsData.sort((a: any, b: any) => (a.clientName.toLowerCase() > b.clientName.toLowerCase() ? 1 : -1));

        setClientItems(sortedClients);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL CLIENT DATA");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleSetNewClient = async () => {
    try {
      const clientResponse = await fetch(ADD_NEW_CLIENTS, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(clientRequest),
      });
      const clientContent = await clientResponse.json();

      // console.log("clientContent", clientContent.data);

      if (clientContent.data.message === "Success") {
        setOpen(false);
        setClientRequest(undefined);
      } else {
        // console.log("failed to add new parameter");
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW CLIENT");
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleDeleteClientByRef = async () => {
    const deleteRequest = {
      docReference: selectedClient !== undefined ? selectedClient.reference : "",
    };

    try {
      const deleteClientResponse = await fetch(DELETE_CLIENT_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(deleteRequest),
      });
      const deleteResponseContent = await deleteClientResponse.json();

      // console.log("sampleStatusContent", sampleStatusContent.data);

      if (deleteResponseContent.data.message === "Success") {
        handleFetchAllClients();
        setDeleteOpen(false);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleGetPermissionByEmail = async () => {
    // console.log("selectedClient", selectedClient);
    const getPermissionRequest = {
      clientEmail: selectedClient !== undefined ? selectedClient.clientEmail : "",
    };

    // console.log("getPermissionRequest", getPermissionRequest);

    try {
      const getPermissionResponse = await fetch(GET_PERMISSION_BY_EMAIL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(getPermissionRequest),
      });
      const getPermissionResponseContent = await getPermissionResponse.json();

      // console.log("getPermissionResponseContent", getPermissionResponseContent);

      if (getPermissionResponseContent.data.message === "Success") {
        return getPermissionResponseContent.data.result.permissionData;
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
        return undefined;
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleDeletePermissionByRef = async (reference: string) => {
    const deleteRequest = {
      docReference: reference,
    };

    try {
      const deletePermissionResponse = await fetch(DELETE_PERMISSION_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(deleteRequest),
      });
      const deleteResponseContent = await deletePermissionResponse.json();

      // console.log("sampleStatusContent", sampleStatusContent.data);

      if (deleteResponseContent.data.message === "Success") {
        await handleDeleteClientByRef();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO DELETE CLIENT");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleUpdateClient = async () => {
    const updateClientRequest = {
      ...clientRequest,
      docReference: selectedClient?.reference,
    };

    try {
      const updateClientResponse = await fetch(UPDATE_CLIENT_BY_REF, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(updateClientRequest),
      });
      const updateClientContent = await updateClientResponse.json();

      // console.log("permissionContent", permissionContent.data);

      if (updateClientContent.data.message === "Success") {
        handleFetchAllClients();
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO UPDATE CLIENT");
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  // TODO reset of clientRequest value is not working properly
  const handleResetClientRequest = async () => {
    setClientRequest({
      clientEmail: "",
      clientId: "",
      clientName: "",
      clientAddress: "",
    });
  };

  const handleSuccessModal = async () => {
    setFetching(true);
    if (clientRequest !== undefined) {
      if (clientRequest.clientEmail !== "" && clientRequest.clientName !== "" && clientRequest.clientId !== "") {
        networkActionRef.current = true;
        await handleSetNewClient();
        await handleFetchAllClients();
        await handleResetClientRequest();
        setFormErrorMessage("");
        networkActionRef.current = false;
      } else if (clientRequest.clientEmail === "") {
        setFormErrorMessage("Client email is required");
      } else if (clientRequest.clientId === "") {
        setFormErrorMessage("Client id is required");
      } else {
        setFormErrorMessage("Client name is required");
      }
    }
    setFetching(false);
  };

  const handleSuccessEditModal = async () => {
    setFetching(true);
    // console.log("clientRe", clientRequest);
    // console.log("clientRequest", clientRequest);
    if (clientRequest !== undefined) {
      if (clientRequest.clientEmail !== "" && clientRequest.clientName !== "") {
        await handleUpdateClient();
        setEditOpen(false);
        await handleResetClientRequest();
      } else if (clientRequest.clientEmail === "") {
        setFormErrorMessage("Client email is required");
      } else {
        setFormErrorMessage("Client name is required");
      }
    }
    setFetching(false);
  };

  const handleSuccessDeleteClient = async () => {
    setFetching(true);
    const permissionResponse = await handleGetPermissionByEmail();
    // console.log("permissionResponse", permissionResponse);
    if (permissionResponse !== undefined && Object.keys(permissionResponse).length !== 0) {
      // delete permission if there's permission data correspondece to this client
      await handleDeletePermissionByRef(permissionResponse.reference);
    } else {
      await handleDeleteClientByRef();
    }
    setFetching(false);
    // delete client
    // await handleDeleteClientByRef();
  };

  const content = (
    <div>
      <Box>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 12 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </div>
  );

  useEffect(() => {
    setFetching(true);
    handleFetchAllClients();
    setFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <ContentContainer>
      <div
        style={{
          flex: 1,
          backgroundColor: "white",
          borderRadius: 12,
          // boxShadow: "40 40",
          borderWidth: 1,
          borderColor: "#31304D",
          borderStyle: "solid",
          height: window.innerHeight - 24,
          marginTop: 12,
          // overflowY: "scroll",
        }}>
        <Box flex={1} justifyContent={"flex-end"} flexDirection={"row"}>
          <Box height={4} width={4} />
          <Box marginRight={w8}>
            <Spacer space={h4} />
            <Button variant="outlined" style={{ color: "#31304D", borderColor: "#31304D", height: 34 }} onClick={handleOpenModal}>
              Add New Client +
            </Button>
            <Spacer space={h4} />
          </Box>
        </Box>

        <div
          style={{
            backgroundColor: "#31304D",
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            height: h48,
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}>
          <ListingTitleClient />
        </div>
        <Spacer space={h4} />
        <div style={{ overflowY: "scroll", height: window.innerHeight - h48 - h12 - 24 - 34 }}>
          <ClientListingParameter
            clients={clientItems}
            setDeleteOpen={setDeleteOpen}
            setEditOpen={setEditOpen}
            setSelectedClient={setSelectedClient}
          />
        </div>
      </div>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* fab button to open modal */}
      {/* <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        onClick={handleOpenModal}
        style={{ position: "absolute", right: w24, bottom: h24 }}>
        Add new client
      </Fab> */}

      {/* modal to add new client */}
      <Modal
        isOpen={open}
        onClose={() => {
          handleResetClientRequest();
          setOpen(false);
        }}
        safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Add New Client</Modal.Header>
          <Modal.Body>
            <AddNewClient
              setClientRequest={setClientRequest}
              formErrorMessage={formErrorMessage}
              setFormErrorMessage={setFormErrorMessage}
              networkActionRef={networkActionRef.current}
              existingClients={clientItems}
            />
            {/* <AddNewParameter setParameterRequest={setParameterRequest} /> */}
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to update client */}
      <Modal
        isOpen={editOpen}
        onClose={() => {
          setEditOpen(false);
          handleResetClientRequest();
        }}
        safeAreaTop={true}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>
            Update Client {`(${selectedClient !== undefined && selectedClient.clientId !== undefined ? selectedClient.clientId : "-"})`}
          </Modal.Header>
          <Modal.Body>
            <UpdateClient
              setClientRequest={setClientRequest}
              formErrorMessage={formErrorMessage}
              setFormErrorMessage={setFormErrorMessage}
              selectedClient={selectedClient}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessEditModal}>
                Confirm
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>

      {/* modal to delete client */}
      <Modal isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Delete the client ?</Modal.Header>
          <Modal.Body>
            <Text>Are you sure you want to delete this client ?</Text>
            <Text fontWeight={"bold"}>{selectedClient !== undefined ? selectedClient.clientName : "-"}</Text>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDeleteOpen(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleSuccessDeleteClient}>
                Confirm Delete
              </Button>
            </ButtonGroup>
          </Modal.Footer>
          {fetching === true ? content : null}
        </Modal.Content>
      </Modal>
    </ContentContainer>
  );
};
