import { Checkbox } from "@mui/material";
import { Box, CheckIcon, FormControl, HStack, Input, Radio, Select, Text, View } from "native-base";
import React, { Fragment, useState } from "react";

import { Spacer } from "../../components";
import { ISample } from "../../context";
import { h16, h24, h4, w16, w4, w8 } from "../../styles";
import { INewSamples } from "./AddMultipleSample";
import { padNumber } from "./helper";

interface IAddIndexSample {
  state: INewSamples;
  dispatch: React.Dispatch<{
    type: string;
    item: any;
  }>;
  clientItems: IClientItems[];
  setIsNewCustomer: (value: boolean) => void;
  isNewCustomer: boolean;
  formErrorMessage: string;
}

export const AddIndexSample = ({ state, dispatch, clientItems, setIsNewCustomer, isNewCustomer, formErrorMessage }: IAddIndexSample) => {
  const [selectedCheckBox, setSelectedCheckBox] = useState<number[]>([]);
  const [isNewBatch, setIsNewBatch] = useState(true);
  const [sampleNextNumber, setSampleNextNumber] = useState("");
  const CHECKBOX_OPTION = ["Sample Location", "Sample Point", "Sample Type", "Sample Name", "Sampling Date", "Received Date"];

  // update reducer functions
  // handler client details
  const handleUpdateClientName = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = state.sampleData.map((eachSample) => {
      return { ...eachSample, clientName: value };
    });

    dispatch({ type: "add_sample_number", item: { clientName: value, sampleData: sampleArray.length > 0 ? sampleArray : [] } });
  };
  const handleUpdateClientEmail = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = state.sampleData.map((eachSample) => {
      return { ...eachSample, clientEmail: value };
    });

    dispatch({ type: "add_sample_number", item: { clientEmail: value, sampleData: sampleArray.length > 0 ? sampleArray : [] } });
  };
  const handleUpdateClientId = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = state.sampleData.map((eachSample) => {
      return { ...eachSample, clientId: value };
    });

    dispatch({ type: "add_sample_number", item: { clientId: value, sampleData: sampleArray.length > 0 ? sampleArray : [] } });
  };

  // handler sample id
  const handleUpdateSampleIdIndex = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = state.sampleData;
    const updatedSampleArray: Omit<ISample, "reference">[] = [];

    if (sampleArray.length > 0) {
      const startingNumber = isNewBatch === true ? 0 : parseInt(sampleNextNumber !== "" ? sampleNextNumber : "0", 10) - 1;
      const sampleLimit = isNewBatch === true ? parseInt(state.sampleQuantity, 10) : parseInt(state.sampleQuantity, 10) + startingNumber;
      for (let index = startingNumber; index < sampleLimit; index++) {
        const isThousands = (index + 1).toString().length > 3;
        updatedSampleArray.push({
          ...sampleArray[index],
          sampleId: `${value}.${padNumber(index + 1, isThousands ? 4 : 3)}`,
        });
      }
    }

    dispatch({ type: "add_sample_number", item: { sampleIdIndex: value, sampleData: sampleArray.length > 0 ? updatedSampleArray : [] } });
  };
  // const handleUpdateBatchNumber = (value: string) => {
  //   const sampleArray: Omit<ISample, "reference">[] = state.sampleData.map((eachSample) => {
  //     return { ...eachSample, batchNumber: value };
  //   });

  //   dispatch({ type: "add_sample_number", item: { batchNumber: value, sampleData: sampleArray.length > 0 ? sampleArray : [] } });
  // };

  const handleUpdateSampleQuantity = (value: string) => {
    const newSampleItem: Omit<ISample, "reference"> = {
      clientEmail: state.clientEmail,
      clientId: state.clientId,
      clientName: state.clientName,
      clientRequestor: state.clientRequestor,
      batchNumber: "",
      sampleLocation: "",
      touchPoint: "",
      sampleId: "",
      sampleType: "",
      sampleName: "",
      samplingDate: "",
      receivedDate: "",
      reportRef: [],
      isCompleted: false,
    };
    const sampleArray: Omit<ISample, "reference">[] = [];

    const startingNumber = isNewBatch === true ? 0 : parseInt(sampleNextNumber !== "" ? sampleNextNumber : "0", 10) - 1;
    const sampleLimit = isNewBatch === true ? parseInt(value, 10) : parseInt(value, 10) + startingNumber;
    for (let index = startingNumber; index < sampleLimit; index++) {
      const isThousands = (index + 1).toString().length > 3;
      sampleArray.push({
        ...newSampleItem,
        sampleId: `${state.sampleIdIndex}.${padNumber(index + 1, isThousands ? 4 : 3)}`,
      });
    }
    const updateSampleData = state.sampleIdIndex !== "" && value !== "" && parseInt(value, 10);

    dispatch({ type: "add_sample_number", item: { sampleQuantity: value, sampleData: updateSampleData ? sampleArray : [] } });
  };

  // handler common sample details
  const handleUpdateClientRequestor = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, clientRequestor: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, clientRequestor: value } });
  };
  const handleUpdateSampleLocation = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, sampleLocation: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, sampleLocation: value } });
  };
  const handleUpdateTouchPoint = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, touchPoint: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, touchPoint: value } });
  };
  const handleUpdateSampleType = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, sampleType: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, sampleType: value } });
  };
  const handleUpdateSampleName = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, sampleName: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, sampleName: value } });
  };
  const handleUpdateSamplingDate = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, samplingDate: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, samplingDate: value } });
  };
  const handleUpdateReceivedDate = (value: string) => {
    const sampleArray: Omit<ISample, "reference">[] = [];
    state.sampleData.forEach((eachSample) => {
      sampleArray.push({ ...eachSample, receivedDate: value });
    });
    dispatch({ type: "add_sample_number", item: { sampleData: sampleArray, receivedDate: value } });
  };

  const handleUpdateNextSampleNumber = (nextNumber: string) => {
    setSampleNextNumber(nextNumber);
  };

  const handleCheckBoxSelected = (value: number) => {
    const valueIndex = selectedCheckBox.indexOf(value);
    const updatedSelected = [...selectedCheckBox];
    if (valueIndex === -1) {
      setSelectedCheckBox([...updatedSelected, value]);
    } else {
      updatedSelected.splice(valueIndex, 1);
      setSelectedCheckBox(updatedSelected);
    }
  };

  const handleUpdateBatch = (value: boolean) => {
    setIsNewBatch(value);
  };

  const sampleIdExample = state.sampleData
    .map((eachSample) => eachSample.sampleId)
    .join(", ")
    .slice(0, 100);

  // const filteredClientItems: IClientItems[] = [];
  // clientItems
  //   .sort((a, b) => (a.clientName > b.clientName ? 1 : -1))
  //   .forEach((eachClient) => {
  //     const isUnique = filteredClientItems.some((filteredClient) => filteredClient.clientId === eachClient.clientId) === false;
  //     if (isUnique) {
  //       filteredClientItems.push(eachClient);
  //     }
  //   });

  // console.log("clientItems", clientItems);

  return (
    <Box>
      {/* select company */}
      <HStack space={w4}>
        <View flex={1}>
          <FormControl w="3/4" maxW="300">
            <FormControl.Label>Choose Company *</FormControl.Label>
            <Select
              minWidth="200"
              placeholder="Choose Company"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={5} />,
              }}
              selectedValue={!isNewCustomer ? state.clientEmail : "others"}
              onValueChange={(value) => {
                if (value === "others") {
                  // free text of client name & client email
                  setIsNewCustomer(true);
                  handleUpdateClientName("");
                  handleUpdateClientEmail("");
                  handleUpdateClientId("");
                } else {
                  setIsNewCustomer(false);
                  const selectedClient = clientItems.filter((eachClient) => eachClient.clientEmail === value);
                  if (selectedClient.length > 0) {
                    handleUpdateClientName(selectedClient[0].clientName);
                    handleUpdateClientEmail(selectedClient[0].clientEmail);
                    handleUpdateClientId(selectedClient[0].clientId);
                  }
                }
              }}
              mt="1">
              {clientItems.map((eachCompany, eachCompanyIndex) => {
                return (
                  <Select.Item key={`clientItems-${eachCompanyIndex}`} label={eachCompany.clientName} value={eachCompany.clientEmail} />
                );
              })}
              <Select.Item label={"Others"} value={"others"} />
            </Select>
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("company") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
            <Spacer space={h24} />
          </FormControl>
        </View>

        <View flex={1}>
          <FormControl.Label>Company Name *</FormControl.Label>
          <Input
            autoCapitalize={"none"}
            onChangeText={handleUpdateClientName}
            placeholder={"company name"}
            value={state.clientName}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: 300,
            }}
            isDisabled={!isNewCustomer}
          />
          {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("name") ? (
            <View>
              <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                **{formErrorMessage}
              </Text>
            </View>
          ) : null}
        </View>
        {isNewCustomer ? (
          <View flex={1.5} flexDirection={"row"}>
            <View flex={1} marginRight={w4}>
              <FormControl.Label>Company Email *</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateClientEmail}
                placeholder={"company email"}
                value={state.clientEmail}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
              {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("email") ? (
                <View>
                  <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                    **{formErrorMessage}
                  </Text>
                </View>
              ) : null}
            </View>

            <View flex={1}>
              <FormControl.Label>Company Id *</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateClientId}
                placeholder={"company id"}
                value={state.clientId}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
              {formErrorMessage !== "" &&
              formErrorMessage.toLocaleLowerCase().includes("id") &&
              formErrorMessage.toLocaleLowerCase().includes("client") ? (
                <View>
                  <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                    *{formErrorMessage}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        ) : null}
      </HStack>

      <FormControl>
        <HStack space={w16}>
          <View>
            <FormControl.Label
              _text={{
                fontSize: "sm",
                bold: true,
              }}>
              New Batch ?
            </FormControl.Label>
            <Radio.Group
              name="New Batch ?"
              value={isNewBatch ? "1" : "2"}
              onChange={(value) => {
                handleUpdateBatch(value === "1" ? true : false);
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Yes
                </Radio>
                <Radio value="2" my="2">
                  No
                </Radio>
              </HStack>
            </Radio.Group>
          </View>

          {/* <View>
            <FormControl.Label>Batch Number *</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateBatchNumber}
              placeholder={"eg: 385"}
              value={state.batchNumber}
              isDisabled={state.clientEmail === ""}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
            {formErrorMessage !== "" && formErrorMessage.toLocaleLowerCase().includes("batch") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View> */}

          {isNewBatch === true ? null : (
            <View>
              <FormControl.Label>Sample Starting Number *</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateNextSampleNumber}
                placeholder={"eg: 54"}
                value={sampleNextNumber}
                isDisabled={state.sampleIdIndex === ""}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
              {formErrorMessage !== "" &&
              formErrorMessage.toLocaleLowerCase().includes("sample") &&
              formErrorMessage.toLocaleLowerCase().includes("id") ? (
                <View>
                  <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                    **
                  </Text>
                </View>
              ) : null}
            </View>
          )}
        </HStack>
        <Spacer space={h16} />

        {/* select client name, requestor, sample id */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample ID Index *</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleIdIndex}
              placeholder={"eg: PL1003"}
              value={state.sampleIdIndex}
              isDisabled={state.clientEmail === ""}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
            {formErrorMessage !== "" &&
            formErrorMessage.toLocaleLowerCase().includes("sample") &&
            formErrorMessage.toLocaleLowerCase().includes("id") ? (
              <View>
                <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                  **{formErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>

          <View flex={1}>
            <FormControl.Label>Sample Quantity *</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleQuantity}
              placeholder={"eg: 20"}
              value={state.sampleQuantity}
              isDisabled={state.sampleIdIndex === ""}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Requestor</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientRequestor}
              placeholder={"requestor name"}
              isDisabled={state.clientEmail === ""}
              value={state.clientRequestor}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>

        {state.sampleQuantity !== "" ? (
          <Fragment>
            <Box alignItems={"flex-start"}>
              <Spacer space={h4} />
              <Text fontSize={"xs"}>Eg: {sampleIdExample} ...</Text>
              <Spacer space={h24} />
            </Box>
          </Fragment>
        ) : null}

        {state.sampleQuantity !== "" ? (
          <Fragment>
            <Box alignItems={"flex-start"}>
              <Text>Select Common Detail:</Text>
            </Box>
            <HStack space={w8}>
              {CHECKBOX_OPTION.map((eachCheckBox, checkBoxIndex) => {
                const isChecked = selectedCheckBox.indexOf(checkBoxIndex) !== -1;
                return (
                  <HStack alignItems={"center"} key={`CHECKBOX_OPTION-${checkBoxIndex}`}>
                    <Checkbox
                      checked={isChecked}
                      onChange={() => handleCheckBoxSelected(checkBoxIndex)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Text>{eachCheckBox}</Text>
                  </HStack>
                );
              })}
            </HStack>
          </Fragment>
        ) : null}

        {/* common details section */}
        <HStack space={w8} flexWrap={"wrap"}>
          {selectedCheckBox.indexOf(0) !== -1 ? (
            <View flex={"20%"}>
              <FormControl.Label>Sample Location</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateSampleLocation}
                placeholder={"sample location"}
                value={state.sampleLocation}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View>
          ) : null}
          {selectedCheckBox.indexOf(1) !== -1 ? (
            <View flex={"20%"}>
              <FormControl.Label>Sample Point</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateTouchPoint}
                placeholder={"touch point"}
                value={state.touchPoint}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View>
          ) : null}
          {selectedCheckBox.indexOf(2) !== -1 ? (
            <View flex={"20%"}>
              <FormControl.Label>Sample Type</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateSampleType}
                placeholder={"sample type"}
                value={state.sampleType}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View>
          ) : null}
          {selectedCheckBox.indexOf(3) !== -1 ? (
            <View flex={"20%"}>
              <FormControl.Label>Sample Name</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateSampleName}
                placeholder={"sample name"}
                value={state.sampleName}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View>
          ) : null}
          {selectedCheckBox.indexOf(4) !== -1 ? (
            <View flex={"20%"}>
              <FormControl.Label>Sampling Date</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateSamplingDate}
                placeholder={"sampling date"}
                value={state.samplingDate}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View>
          ) : null}
          {selectedCheckBox.indexOf(5) !== -1 ? (
            <View flex={"20%"}>
              <FormControl.Label>Received Date</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={handleUpdateReceivedDate}
                placeholder={"received date"}
                value={state.receivedDate}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View>
          ) : null}
        </HStack>
      </FormControl>
    </Box>
  );
};
