import React from "react";

interface IListingLabelProps {
  label: string;
  boxWidth?: number | string;
  customFlex?: number;
}

export const ListingLabel: React.FC<IListingLabelProps> = ({ label, customFlex }) => {
  return (
    <div
      style={{
        flex: customFlex !== undefined ? customFlex : 1,
        flexWrap: "wrap",
        justifyContent: "start",
        // backgroundColor: "lightgreen"
        textAlign: "start",
      }}>
      <span
        style={{
          fontWeight: "700",
          color: "#FFFBF5",
          fontSize: 16,
          opacity: 0.9,
        }}>
        {label}
      </span>
    </div>
  );
};
