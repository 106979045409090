import { Box, HStack, Text } from "native-base";
import React from "react";

interface IBackIcon {
  onPress: () => void;
}

export const BackIcon = ({ onPress }: IBackIcon) => {
  return (
    <div onClick={onPress} style={{ cursor: "pointer" }}>
      <Box>
        <HStack space={2} alignItems={"center"}>
          {/* <Icon as={Ionicons} name={"arrow-back-outline"} size="6" color="red" /> */}
          <Text fontWeight={"semibold"}>Back</Text>
        </HStack>
      </Box>
    </div>
  );
};
