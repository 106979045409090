import { Backdrop, CircularProgress, Fab } from "@mui/material";
import { Box, Text, View } from "native-base";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, GlobalContainer } from "../components";
import { GET_ALL_SAMPLE_FOR_ADMIN } from "../constants";
import { GlobalContext, ISample, ISampleAmount, ISearchDashboard } from "../context";
import { useKeyDown } from "../hooks";
import { h24, h8, h80, w24, w8 } from "../styles";
import { getLocalStorage, setLocalStorage } from "../utils";
import { DashboardClient } from "./client/DashboardClient";
import { CompletedListing } from "./dashboard/CompletedListing";
import { OngoingListing } from "./dashboard/OngoingListing";
import { PlantOverview } from "./dashboard/PlantOverview";
import { DashboardParameter } from "./parameter/DashboardParameter";
import { DashboardPermission } from "./permission/DashboardPermission";
import { SampleDetail } from "./sampleDetail/SampleDetail";

export interface ITabRoutes {
  key: string;
  title: string;
  // render: JSX.Element;
}

export const Dashboard = () => {
  const navigate = useNavigate();
  // const privateNavigation = useNavigation<IStackNavigationPrivateProp>();
  // const handleShowSampleDetail = () => {
  //   privateNavigation.navigate("SampleDetail");
  // };
  const { globalState, handleInitAllSamplesAdmin } = useContext(GlobalContext);
  const { selectedMenu: contextSelectedMenu, adminEmail, searchDashboard } = globalState;
  const { searchText } = searchDashboard;
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedSampleRef, setSelectedSampleRef] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState<boolean>(false);
  const [localOngoingSamples, setlocalOngoingSamples] = useState<ISample[]>([]);
  const [localCompletedSamples, setlocalCompletedSamples] = useState<ISample[]>([]);
  const pageRef = useRef<number>(1);

  const [routes] = useState<ITabRoutes[]>([
    {
      key: "Ongoing",
      title: "Ongoing",
    },
    {
      key: "Completed",
      title: "Completed",
    },
  ]);

  const [activeTab, setActiveTab] = useState<string>("Ongoing");
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const handleCloseDetailPage = () => {
    setShowDetail(false);
  };

  const handleShowSampleDetail = (value: string | undefined) => {
    setSelectedSampleRef(value);
    setShowDetail(!showDetail);
  };

  const handleUpdateTab = (value: string) => {
    setActiveTab(value);
  };

  const handleGetAllSampleOfAllClient = async (value: number, searchDashboard?: ISearchDashboard) => {
    const paginationRequest = {
      page: value,
      tab: activeTab,
      searchText: searchDashboard !== undefined ? searchDashboard.searchText : "",
      byCategory: searchDashboard !== undefined ? searchDashboard.byCategory : "",
    };
    try {
      const samplesResponse = await fetch(GET_ALL_SAMPLE_FOR_ADMIN, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(paginationRequest),
      });
      const samplesContent = await samplesResponse.json();

      if (samplesContent.data.message === "Success") {
        const samplesOfClient = samplesContent.data.result.data;
        return samplesOfClient;
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL SAMPLES DATA FOR ADMIN");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleAddOneSample = () => {
    navigate("/add-one-sample");
  };

  const handleAddMultipleSample = () => {
    navigate("/add-multiple-sample");
  };

  const globalContainerProps = {
    handleCloseDetailPage: handleCloseDetailPage,
  };

  const contentProps = {
    handleShowSampleDetail: handleShowSampleDetail,
    localOngoingSamples: localOngoingSamples,
    localCompletedSamples: localCompletedSamples,
  };

  const sampleDetailsProps = {
    handleShowSampleDetail: handleShowSampleDetail,
    selectedSampleRef: selectedSampleRef,
    setError: setError,
    error: error,
    setErrorMessage: setErrorMessage,
  };

  const handleUpdatePagination = async (page: number) => {
    setFetching(true);
    pageRef.current = page;
    const isSearchDashboard = searchDashboard.searchText !== "" ? searchDashboard : undefined;
    const updateSamples = await handleGetAllSampleOfAllClient(page, isSearchDashboard);
    setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
    const samplesAmount: ISampleAmount = {
      currentPage: page,
      ongoingDocs: updateSamples.ongoingDocs,
      completedDocs: updateSamples.completedDocs,
    };
    await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined);
    if (activeTab === "Ongoing") {
      setlocalOngoingSamples(updateSamples.sampleItems);
    } else {
      setlocalCompletedSamples(updateSamples.sampleItems);
    }
    setFetching(false);
  };

  const handleUpdateSearchDashboard = async (searchDashboard: ISearchDashboard) => {
    setFetching(true);
    pageRef.current = 1;
    const updateSamples = await handleGetAllSampleOfAllClient(1, searchDashboard);
    setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
    const samplesAmount: ISampleAmount = {
      currentPage: 1,
      ongoingDocs: updateSamples.ongoingDocs,
      completedDocs: updateSamples.completedDocs,
    };
    const updatedSearchDashboard = searchDashboard.searchText === "" ? searchDashboard : undefined;
    await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined, updatedSearchDashboard);
    if (activeTab === "Ongoing") {
      setlocalOngoingSamples(updateSamples.sampleItems);
    } else {
      setlocalCompletedSamples(updateSamples.sampleItems);
    }
    setFetching(false);
  };

  let tabContent: JSX.Element = <View />;
  switch (activeTab) {
    case "Ongoing":
      tabContent = <OngoingListing {...contentProps} />;
      break;
    case "Completed":
      tabContent = <CompletedListing {...contentProps} />;
      break;
    default:
      tabContent = (
        <View>
          <Text>No content</Text>
        </View>
      );
      break;
  }

  let menuContent = (
    <Fragment>
      <PlantOverview
        activeTab={activeTab}
        handleUpdateTab={handleUpdateTab}
        handleUpdatePagination={handleUpdatePagination}
        handleUpdateSearchDashboard={handleUpdateSearchDashboard}
        tabRoutes={routes}>
        {tabContent}
      </PlantOverview>
    </Fragment>
  );
  switch (contextSelectedMenu) {
    case "Analysis":
      menuContent = (
        <Fragment>
          <PlantOverview
            activeTab={activeTab}
            handleUpdateTab={handleUpdateTab}
            handleUpdatePagination={handleUpdatePagination}
            handleUpdateSearchDashboard={handleUpdateSearchDashboard}
            tabRoutes={routes}>
            {tabContent}
          </PlantOverview>
        </Fragment>
      );
      break;
    case "Client":
      menuContent = (
        <Fragment>
          <DashboardClient />
        </Fragment>
      );
      break;
    case "Permission":
      menuContent = (
        <Fragment>
          <DashboardPermission />
        </Fragment>
      );
      break;
    case "Parameter":
      menuContent = (
        <Fragment>
          <DashboardParameter />
        </Fragment>
      );
      break;

    default:
      menuContent = (
        <View>
          <Text>No content</Text>
        </View>
      );
      break;
  }

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      const isSearchDashboard = searchDashboard.searchText !== "" ? searchDashboard : undefined;
      const updateSamples = await handleGetAllSampleOfAllClient(pageRef.current, isSearchDashboard);
      setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
      // const sortedDashboard = updateSamples.sampleItems.sort((a: any, b: any) => (a.sampleId > b.sampleId ? -1 : 1));
      const samplesAmount: ISampleAmount = {
        currentPage: pageRef.current,
        ongoingDocs: updateSamples.ongoingDocs,
        completedDocs: updateSamples.completedDocs,
      };
      await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined);
      if (activeTab === "Ongoing") {
        setlocalOngoingSamples(updateSamples.sampleItems);
      } else {
        setlocalCompletedSamples(updateSamples.sampleItems);
      }
      setFetching(false);
    };

    // if (contextSelectedMenu === "Analysis") {
    fetchData();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSelectedMenu, showDetail, activeTab]);

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      const isSearchDashboard = searchDashboard.searchText !== "" ? searchDashboard : undefined;
      const updateSamples = await handleGetAllSampleOfAllClient(pageRef.current, isSearchDashboard);
      setLocalStorage("total-page-dashboard", `${updateSamples.totalPage}`);
      // const sortedDashboard = updateSamples.sampleItems.sort((a: any, b: any) => (a.sampleId > b.sampleId ? -1 : 1));
      const samplesAmount: ISampleAmount = {
        currentPage: pageRef.current,
        ongoingDocs: updateSamples.ongoingDocs,
        completedDocs: updateSamples.completedDocs,
      };
      await handleInitAllSamplesAdmin(adminEmail, updateSamples.sampleItems, samplesAmount, undefined);
      if (activeTab === "Ongoing") {
        setlocalOngoingSamples(updateSamples.sampleItems);
      } else {
        setlocalCompletedSamples(updateSamples.sampleItems);
      }
      setFetching(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  useKeyDown(() => {
    if (searchText !== "") {
      handleUpdateSearchDashboard(searchDashboard);
    }
  }, ["Enter"]);

  return (
    <GlobalContainer {...globalContainerProps}>
      <Fragment>
        {showDetail ? (
          <div style={{ overflowY: "scroll", height: window.innerHeight, backgroundColor: "white" }}>
            <SampleDetail {...sampleDetailsProps} />
          </div>
        ) : (
          <Fragment>{menuContent}</Fragment>
        )}
        {/* alert show error message  */}
        <Box position={"absolute"} right={w8} top={h8}>
          {error ? <CustomAlert alertMessage={errorMessage} /> : null}
        </Box>

        {/* {contextSelectedMenu === "Analysis" && showDetail === false ? (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={handleAddOneSample}
            style={{ position: "absolute", right: w24, bottom: h24 }}>
            Add one sample
          </Fab>
        ) : null}

        {contextSelectedMenu === "Analysis" && showDetail === false ? (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={handleAddMultipleSample}
            style={{ position: "absolute", right: w24, bottom: h80 }}>
            Add Samples
          </Fab>
        ) : null} */}

        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 + 12 }} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    </GlobalContainer>
  );
};
