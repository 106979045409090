import { Box, Text, useColorModeValue } from "native-base";
import { useContext, useState } from "react";

import { GlobalContext } from "../../context";
import { ITabRoutes } from "../../pages/Dashboard";

interface ICustomTabContainer {
  tabRoutes: ITabRoutes[];
  handleUpdateTab: (value: string) => void;
  activeTab: string;
  //   children: ReactNode;
}

export const CustomTabContainer = ({ tabRoutes, handleUpdateTab, activeTab }: ICustomTabContainer) => {
  const [routes] = useState(tabRoutes);

  const { globalState } = useContext(GlobalContext);
  const { sampleAmount } = globalState;

  const numberOfOngoingSamples = sampleAmount.ongoingDocs;
  const numberOfCompletedSamples = sampleAmount.completedDocs;
  // const numberOfOngoingSamples = allSamplesForAdmin.filter((eachSample) => eachSample.isCompleted === false).length;
  // const numberOfCompletedSamples = allSamplesForAdmin.filter((eachSample) => eachSample.isCompleted === true).length;

  return (
    <Box>
      <Box flexDirection="row" marginLeft={2}>
        {routes.map((eachRoute, index) => {
          const { key, title } = eachRoute;

          let samplesCount = "";
          switch (title) {
            case "Ongoing":
              samplesCount = `  (${numberOfOngoingSamples})`;
              break;
            case "Completed":
              samplesCount = `  (${numberOfCompletedSamples})`;
              break;

            default:
              samplesCount = "";
              break;
          }

          //   const color = activeTab === index ? useColorModeValue("#000", "#e5e5e5") : "muted.300";
          const borderColor = activeTab === title ? "#64638a" : "transparent";

          const handleOnClickTab = () => {
            handleUpdateTab(title);
          };

          return (
            <div
              onClick={handleOnClickTab}
              style={{
                cursor: "pointer",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#64638a",
                borderRadius: 8,
                marginRight: 4,
                backgroundColor: borderColor,
              }}
              key={`${index}${key}`}>
              <Box
                marginRight={8}
                // borderBottomWidth="3"
                // borderColor={borderColor}
                // flex={1}
                alignItems="center"
                p="3">
                <Text color={activeTab === title ? "white" : "#64638a"} fontWeight={activeTab === title ? "700" : "500"}>
                  {`${title}${samplesCount}`}
                </Text>
              </Box>
            </div>
          );
        })}
      </Box>
    </Box>
  );
};
