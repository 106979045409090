import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Box, Heading, HStack, Modal, View } from "native-base";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomAlert, LabelledItemBoxed, Spacer } from "../../components";
import { ADD_NEW_CLIENTS, ADD_NEW_ONE_SAMPLE, GET_ALL_CLIENTS } from "../../constants";
import { ISample } from "../../context";
import { h16, h24, h8, w1, w12, w24, w8 } from "../../styles";
import { getLocalStorage, useDebounce } from "../../utils";
import { AddIndexSample } from "./AddIndexSample";
import { AddMultipleClientDetails } from "./AddMultipleClientDetails";

export interface INewSamples {
  sampleQuantity: string;
  batchNumber: string;
  sampleIdIndex: string;
  clientRequestor: string;
  clientEmail: string;
  clientId: string;
  clientName: string;
  sampleLocation?: string;
  touchPoint?: string;
  sampleType?: string;
  sampleName?: string;
  samplingDate?: string;
  receivedDate?: string;
  sampleData: Omit<ISample, "reference">[];
}

// {
//     clientEmail: "",
//     clientId: "",
//     clientName: "",
//     sampleLocation: "",
//     touchPoint: "",
//     clientRequestor: "",
//     sampleId: "",
//     sampleType: "",
//     sampleName: "",
//     samplingDate: "",
//     receivedDate: "",
//     reportRef: [],
//     isCompleted: false,
//   },

export type AddSampleProcess = "client" | "parameter" | "preview";

const initialNewSamples: INewSamples = {
  clientEmail: "",
  clientId: "",
  clientName: "",
  sampleIdIndex: "",
  clientRequestor: "",
  batchNumber: "",
  sampleQuantity: "",
  sampleLocation: "",
  touchPoint: "",
  sampleType: "",
  sampleName: "",
  samplingDate: "",
  receivedDate: "",
  sampleData: [],
};

const reducer = (state: INewSamples, action: { type: string; item: any }) => {
  switch (action.type) {
    case "add_item":
      return {
        ...state,
        sampleData: [...action.item],
      };
    case "add_sample_number":
      return {
        ...state,
        ...action.item,
      };
    case "update_client":
      return {
        ...state,
        ...action.item,
      };
    case "reset":
      return { ...initialNewSamples };
    default:
      return state;
  }
};

export const AddMultipleSample = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialNewSamples);
  const [clientItems, setClientItems] = useState<IClientItems[] | []>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  // const [parameterItems, setParameterItems] = useState<IParameterItems[] | []>([]);
  const [isNewCustomer, setIsNewCustomer] = useState<boolean>(false);
  const [modalPreview, setModalPreview] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  // console.log("clientItems", clientItems);

  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // fetch functions
  const handleFetchAllClients = async () => {
    try {
      const clientResponse = await fetch(GET_ALL_CLIENTS, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
      });
      const clientContent = await clientResponse.json();
      //   console.log("clientContent", clientContent.data);

      if (clientContent.data.message === "Success") {
        const clientsData = clientContent.data.result.data as unknown as IClientItems[];
        // filter same registered client from same company
        const filteredClientItems: IClientItems[] = [];
        clientsData
          .sort((a, b) => (a.clientName > b.clientName ? 1 : -1))
          .filter((eachClient) => eachClient.clientId !== undefined && eachClient.clientId !== "" && eachClient.clientId !== "-")
          .filter((eachClient) => eachClient.clientEmail !== undefined && eachClient.clientEmail !== "" && eachClient.clientEmail !== "-")
          .forEach((eachClient) => {
            const isUnique = filteredClientItems.some((filteredClient) => filteredClient.clientId === eachClient.clientId) === false;
            if (isUnique) {
              filteredClientItems.push(eachClient);
            }
          });
        // console.log("clientsData", clientsData);
        setClientItems(filteredClientItems);
      } else {
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO GET ALL CLIENT DATA");
      }
    } catch (error) {
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };
  // const handleFetchAllParameters = async () => {
  //   try {
  //     const parameterResponse = await fetch(GET_ALL_PARAMETERS, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const parameterContent = await parameterResponse.json();

  //     if (parameterContent.data.message === "Success") {
  //       const parameters = parameterContent.data.result.parameters;
  //       setParameterItems(parameters);
  //     } else {
  //       setError(true);
  //       setErrorMessage("ERROR_MESSAGE: FAILED TO GET CLIENT PROFILE");
  //     }
  //   } catch (error) {
  //     setError(true);
  //     setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
  //   }
  // };

  const handleFetchParamsData = async () => {
    await handleFetchAllClients();
    // await handleFetchAllParameters();
  };
  // const handleAddNewSample = async (eachItem: Omit<ISample, "reference">) => {
  const handleAddNewSample = async (sampleItem: Omit<ISample, "reference">) => {
    try {
      const addNewSampleResponse = await fetch(ADD_NEW_ONE_SAMPLE, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        // body: JSON.stringify(eachItem),
        body: JSON.stringify(sampleItem),
      });
      const addNewSampleContent = await addNewSampleResponse.json();

      // console.log("addNewSampleContent", addNewSampleContent.data);

      if (addNewSampleContent.data.message === "Success") {
        return new Promise((resolve, reject) => {
          resolve("Success");
        });
        // navigate("/dashboard");
      } else {
        // console.log("failed to add new sample");
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW SAMPLE");
        return new Promise((resolve, reject) => {
          resolve("Failed");
        });
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
      return new Promise((resolve, reject) => {
        resolve("Failed");
      });
    }
  };

  const handleSetNewClient = async () => {
    const clientRequest = {
      clientEmail: state.clientEmail,
      clientName: state.clientName,
      clientId: state.clientId,
      clientAddress: "",
    };
    try {
      const clientResponse = await fetch(ADD_NEW_CLIENTS, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorage("id-token")}`,
        },
        body: JSON.stringify(clientRequest),
      });
      const clientContent = await clientResponse.json();

      // console.log("clientContent", clientContent.data);

      if (clientContent.data.message === "Success") {
        // do nothing
      } else {
        // console.log("failed to add new parameter");
        setError(true);
        setErrorMessage("ERROR_MESSAGE: FAILED TO ADD NEW CLIENT");
      }
    } catch (error) {
      // console.log(error);
      setError(true);
      setErrorMessage("ERROR_MESSAGE: NETWORK FAILED");
    }
  };

  const handleAllPromise = async () => {
    const actionArray = state.sampleData.map((eachSample: Omit<ISample, "reference">) => {
      return handleAddNewSample(eachSample);
    });
    let promise = await Promise.all(actionArray);
    // console.log(promise);
    return promise;
  };

  const handleOnSuccessPreviewModal = async () => {
    setFetching(true);
    if (isNewCustomer) {
      await handleSetNewClient();
    }
    // run API to add new sample
    await handleAllPromise();
    setFetching(false);
    navigate("/dashboard");
  };

  const handleNext = async () => {
    if (state.sampleData.clientEmail !== "" && state.sampleData.sampleId !== "" && state.sampleData.clientId !== "") {
      window.scrollTo(0, 0);
      setModalPreview(true);
      // const sampleArray = [state.sampleData, { ...state.sampleData, sampleId: "PL.001.99" }];
      // handleAllPromise();
    } else if (isNewCustomer === true && state.clientName === "") {
      setFormErrorMessage("Client name is required");
    } else if (state.clientEmail === "" && isNewCustomer === true) {
      setFormErrorMessage("Client email is required");
    } else if (state.clientEmail === "") {
      setFormErrorMessage("Select a company");
    } else if (isNewCustomer === true && state.clientId === "") {
      setFormErrorMessage("Client id is required");
    } else {
      setFormErrorMessage("Sample Id is required");
    }
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const handleBack = () => {
    handleCancel();
  };

  const pageProps = {
    state,
    dispatch,
    setIsNewCustomer,
    isNewCustomer,
    formErrorMessage,
  };

  const delayedArray = useDebounce(state.sampleData.length, 500);

  useEffect(() => {
    handleFetchParamsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error === true) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  }, [error]);

  return (
    <Box paddingX={w24} paddingY={h16}>
      <Box>
        <Heading size="xl" ml="-1">
          Add Samples
        </Heading>
        <Spacer space={h24} />
      </Box>
      <AddIndexSample {...pageProps} clientItems={clientItems} />
      <View borderBottomWidth={w1} color={"warmGray.400"} height={h8} marginBottom={h8} />
      {/* {state.sampleData.length > 0 ? ( */}
      {delayedArray > 0 && state.sampleQuantity !== "" ? (
        <AddMultipleClientDetails {...pageProps} clientItems={clientItems} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
      ) : null}

      <HStack space={w12} marginTop={h24}>
        <ButtonGroup variant="outlined" aria-label="outlined button group" style={{ flex: 1 }}>
          <Button aria-label="button" color={"warning"} style={{ cursor: "pointer", flex: 1 }} onClick={handleBack}>
            Cancel
          </Button>
          <Button aria-label="button" style={{ cursor: "pointer", flex: 1 }} onClick={handleNext}>
            Confirm
          </Button>
        </ButtonGroup>
      </HStack>

      {/* alert show error message  */}
      <Box position={"absolute"} right={w8} top={h8}>
        {error ? <CustomAlert alertMessage={errorMessage} /> : null}
      </Box>

      {/* loading network modal */}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5 }} open={fetching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* modal to preview new samples */}
      <Modal isOpen={modalPreview} onClose={() => setModalPreview(false)} safeAreaTop={true} height={window.innerHeight}>
        <Modal.Content maxWidth={window.innerWidth / 2}>
          <Modal.CloseButton />
          <Modal.Header>Samples Preview</Modal.Header>
          <Modal.Body>
            {state.sampleData.map((eachSample: Omit<ISample, "reference">) => {
              return (
                <Fragment>
                  <Box borderWidth={1} marginBottom={h8}>
                    <Box backgroundColor={"amber.200"} borderRadius={4} py={1}>
                      <Heading size={"sm"}>Customer and Sample Information</Heading>
                    </Box>
                    {/* <LabelledItemBoxed label={"Batch Number:"} item={eachSample?.batchNumber} /> */}
                    <LabelledItemBoxed label={"Sample Id:"} item={eachSample?.sampleId} />
                    <LabelledItemBoxed label={"Company Name:"} item={eachSample?.clientName} />
                    <HStack>
                      <Box flex={1} borderRightWidth={1}>
                        <LabelledItemBoxed label={"Requestor"} item={eachSample?.clientRequestor} />
                        <LabelledItemBoxed label={"Location:"} item={eachSample?.sampleLocation} />
                        <LabelledItemBoxed label={"Sample Point:"} item={eachSample?.touchPoint} />
                      </Box>

                      <Box flex={1} borderRightWidth={1}>
                        <LabelledItemBoxed label={"Sample Type:"} item={eachSample?.sampleType} />
                        <LabelledItemBoxed label={"Sample Name:"} item={eachSample?.sampleName} />
                        <LabelledItemBoxed label={"Sampling Date:"} item={eachSample?.samplingDate} />
                        <LabelledItemBoxed label={"Received Date:"} item={eachSample?.receivedDate} />
                      </Box>
                    </HStack>
                  </Box>
                </Fragment>
              );
            })}

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={fetching}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                aria-label="button"
                color={"warning"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModalPreview(false);
                }}>
                Cancel
              </Button>
              <Button aria-label="button" style={{ cursor: "pointer" }} onClick={handleOnSuccessPreviewModal}>
                Confirm Add Samples
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
};
