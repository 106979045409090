import { Box } from "native-base";
import React, { ReactNode, useRef } from "react";

import { h4, w4 } from "../../styles";

interface IContentContainer {
  children: ReactNode;
}

export const ContentContainer = ({ children }: IContentContainer) => {
  return (
    <div
      style={{
        padding: 4,
        height: window.innerHeight,
      }}>
      {children}
    </div>
  );
};
