import React, { useContext } from "react";

import { SampleCard } from "../../components";
import { GlobalContext, ISample } from "../../context";
import { Divider } from "@mui/material";
import { h125 } from "../../styles";

interface ICompletedListing {
  handleShowSampleDetail: (value: string) => void;
  localOngoingSamples: ISample[];
  localCompletedSamples: ISample[];
}

export const CompletedListing = ({ handleShowSampleDetail, localOngoingSamples, localCompletedSamples }: ICompletedListing) => {
  const { globalState } = useContext(GlobalContext);
  const { allSamplesForAdmin, completedSamples } = globalState;

  const sampleCardProps = {
    handleShowSampleDetail: handleShowSampleDetail,
  };

  // console.log("completed allSamplesForAdmin", allSamplesForAdmin);

  return (
    <div style={{ overflowY: "scroll", height: window.innerHeight - h125, backgroundColor: "white" }}>
      {localCompletedSamples.map((eachSample, index) => {
        return (
          <div style={{ paddingTop: 4, paddingBottom: 8 }} key={`${index}${eachSample.sampleId}`}>
            <SampleCard isCompleted={eachSample.isCompleted} {...sampleCardProps} cardItem={eachSample} />
            {<Divider style={{ marginRight: 20, marginLeft: 20 }} />}
          </div>
        );
      })}
    </div>
  );
};
