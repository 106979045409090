import { Alert as NativeAlert, Box, Center, CloseIcon, HStack, IconButton, Text, VStack } from "native-base";
import React from "react";

interface ICustomAlert {
  alertMessage: string;
}

export const CustomAlert = ({ alertMessage }: ICustomAlert) => {
  return (
    <Center>
      <NativeAlert maxW="400" status="info" colorScheme="info">
        <VStack space={2} flexShrink={1} w="100%">
          <HStack
            flexShrink={1}
            space={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack flexShrink={1} space={2} alignItems="center">
              <NativeAlert.Icon />
              <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                Action Failed
              </Text>
            </HStack>
            <IconButton
              variant="unstyled"
              _focus={{
                borderWidth: 0,
              }}
              icon={<CloseIcon size="3" />}
              _icon={{
                color: "coolGray.600",
              }}
            />
          </HStack>
          <Box
            pl="6"
            _text={{
              color: "coolGray.600",
            }}
          >
            {alertMessage}
          </Box>
        </VStack>
      </NativeAlert>
    </Center>
  );
};
