import React from "react";

interface IListingTextProps {
  label: string;
  itemWidth?: number | string;
  flexValue?: number;
  style?: React.CSSProperties;
  textColor?: string;
}

export const ListingText: React.FC<IListingTextProps> = ({ label, flexValue, style, textColor }) => {
  const dynamicColor = textColor !== undefined ? { color: textColor } : { color: "#27272a" };
  return (
    <div
      style={{
        flex: flexValue !== undefined ? flexValue : 1,
        flexWrap: "wrap",
        justifyContent: "start",
        textAlign: "start",
        ...style,
        // backgroundColor: "lightgreen"
      }}>
      <span style={{ fontWeight: "500", fontSize: 16, ...dynamicColor }}>{label.length > 30 ? `${label.slice(0, 60)} ...` : label}</span>
    </div>
  );
};
