import { Button } from "@mui/material";
import { Box, FormControl, HStack, Input, Spacer, Text, View } from "native-base";
import React from "react";

import { h24, h8, w16, w4 } from "../../styles";
import { INewSamples } from "./AddMultipleSample";

interface IAddMultipleClientDetails {
  state: INewSamples;
  dispatch: React.Dispatch<{
    type: string;
    item: any;
  }>;
  clientItems: IClientItems[];
  setIsNewCustomer: (value: boolean) => void;
  isNewCustomer: boolean;
  formErrorMessage: string;
  currentIndex: number;
  setCurrentIndex: (value: number) => void;
}

export const AddMultipleClientDetails = ({
  state,
  dispatch,
  clientItems,
  setIsNewCustomer,
  isNewCustomer,
  formErrorMessage,
  currentIndex,
  setCurrentIndex,
}: IAddMultipleClientDetails) => {
  const currentSampleData = [...state.sampleData];
  const selectedSample = currentSampleData[currentIndex];

  // update reducer functions
  const handleUpdateClientName = (value: string) => {
    dispatch({ type: "add_item", item: { clientName: value } });
  };
  const handleUpdateClientEmail = (value: string) => {
    dispatch({ type: "add_item", item: { clientEmail: value } });
  };
  const handleUpdateClientId = (value: string) => {
    dispatch({ type: "add_item", item: { clientId: value } });
  };
  const handleUpdateSampleLocation = (value: string) => {
    const updatedItem = { ...state.sampleData[currentIndex], sampleLocation: value };
    const updatedArray = [...currentSampleData];
    updatedArray.splice(currentIndex, 1, updatedItem);
    dispatch({ type: "add_item", item: updatedArray });
  };
  const handleUpdateTouchPoint = (value: string) => {
    const updatedItem = { ...state.sampleData[currentIndex], touchPoint: value };
    const updatedArray = [...currentSampleData];
    updatedArray.splice(currentIndex, 1, updatedItem);
    dispatch({ type: "add_item", item: updatedArray });
  };
  const handleUpdateClientRequestor = (value: string) => {
    dispatch({ type: "add_item", item: { clientRequestor: value } });
  };
  const handleUpdateSampleId = (value: string) => {
    dispatch({ type: "add_item", item: { sampleId: value } });
  };
  const handleUpdateSampleType = (value: string) => {
    const updatedItem = { ...state.sampleData[currentIndex], sampleType: value };
    const updatedArray = [...currentSampleData];
    updatedArray.splice(currentIndex, 1, updatedItem);
    dispatch({ type: "add_item", item: updatedArray });
  };
  const handleUpdateSampleName = (value: string) => {
    const updatedItem = { ...state.sampleData[currentIndex], sampleName: value };
    const updatedArray = [...currentSampleData];
    updatedArray.splice(currentIndex, 1, updatedItem);
    dispatch({ type: "add_item", item: updatedArray });
  };
  const handleUpdateSamplingDate = (value: string) => {
    const updatedItem = { ...state.sampleData[currentIndex], samplingDate: value };
    const updatedArray = [...currentSampleData];
    updatedArray.splice(currentIndex, 1, updatedItem);
    dispatch({ type: "add_item", item: updatedArray });
  };
  const handleUpdateReceivedDate = (value: string) => {
    const updatedItem = { ...state.sampleData[currentIndex], receivedDate: value };
    const updatedArray = [...currentSampleData];
    updatedArray.splice(currentIndex, 1, updatedItem);
    dispatch({ type: "add_item", item: updatedArray });
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (currentIndex < state.sampleData.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <Box>
      {state.sampleData.length > 0 ? (
        <Box marginBottom={h8}>
          <HStack justifyContent={"space-between"}>
            {/* <View>
              <FormControl.Label>Batch Number</FormControl.Label>
              <Input
                autoCapitalize={"none"}
                onChangeText={() => {}}
                placeholder={"batch number"}
                value={selectedSample.batchNumber}
                isDisabled={true}
                variant="outline"
                style={{
                  padding: w16,
                  borderRadius: 4,
                  width: 300,
                }}
              />
            </View> */}

            <HStack flex={1} space={w4} justifyContent={"flex-end"} alignItems={"center"}>
              <Button variant="contained" onClick={handlePrev}>
                Prev
              </Button>
              <Text fontWeight={"bold"}>{state.sampleData[currentIndex].sampleId}</Text>
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </HStack>
          </HStack>
        </Box>
      ) : null}

      <FormControl>
        {/* select client name, requestor, sample id */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample ID</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleId}
              placeholder={"sample id"}
              value={selectedSample.sampleId}
              isDisabled={true}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
          <View flex={1}>
            <FormControl.Label>Company Name</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientName}
              placeholder={"company name"}
              value={state.clientName}
              isDisabled={true}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
          {isNewCustomer ? (
            <View flex={1.5} flexDirection={"row"}>
              <View flex={1} marginRight={w4}>
                <FormControl.Label>Company Email *</FormControl.Label>
                <Input
                  autoCapitalize={"none"}
                  onChangeText={handleUpdateClientEmail}
                  placeholder={"company email"}
                  value={state.clientEmail}
                  isDisabled={true}
                  variant="outline"
                  style={{
                    padding: w16,
                    borderRadius: 4,
                    width: 300,
                  }}
                />
              </View>

              <View flex={1}>
                <FormControl.Label>Company Id *</FormControl.Label>
                <Input
                  autoCapitalize={"none"}
                  onChangeText={handleUpdateClientId}
                  placeholder={"company id"}
                  value={state.clientId}
                  isDisabled={true}
                  variant="outline"
                  style={{
                    padding: w16,
                    borderRadius: 4,
                    width: 300,
                  }}
                />
              </View>
            </View>
          ) : null}
          <View flex={isNewCustomer ? 0.5 : 1}>
            <FormControl.Label>Requestor</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateClientRequestor}
              placeholder={"requestor"}
              value={state.sampleData[currentIndex].clientRequestor}
              isDisabled={true}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample info */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample Location</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleLocation}
              placeholder={"sample location"}
              value={state.sampleData[currentIndex].sampleLocation}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
          <View flex={1}>
            <FormControl.Label>Sample Point</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateTouchPoint}
              placeholder={"touch point"}
              value={state.sampleData[currentIndex].touchPoint}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample type, name, isUsed */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sample Type</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleType}
              placeholder={"sample type"}
              value={state.sampleData[currentIndex].sampleType}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Sample Name</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSampleName}
              placeholder={"sample name"}
              value={state.sampleData[currentIndex].sampleName}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
        <Spacer space={h24} />

        {/* section sample date */}
        <HStack space={w4}>
          <View flex={1}>
            <FormControl.Label>Sampling Date</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateSamplingDate}
              placeholder={"sampling date"}
              value={state.sampleData[currentIndex].samplingDate}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>

          <View flex={1}>
            <FormControl.Label>Received Date</FormControl.Label>
            <Input
              autoCapitalize={"none"}
              onChangeText={handleUpdateReceivedDate}
              placeholder={"received date"}
              value={state.sampleData[currentIndex].receivedDate}
              variant="outline"
              style={{
                padding: w16,
                borderRadius: 4,
                width: 300,
              }}
            />
          </View>
        </HStack>
      </FormControl>
    </Box>
  );
};
