import { Backdrop, CircularProgress, MenuItem, TextField } from "@mui/material";
import { Box, FormControl, HStack, Input, Radio, Text, View } from "native-base";
import React, { useEffect, useReducer, useState } from "react";

import { w16, w8 } from "../../styles";

interface INewClient {
  parameterData: Omit<IClientItems, "reference">;
  isSubmitted: boolean;
}

const initialNewClient: INewClient = {
  isSubmitted: false,
  parameterData: {
    clientEmail: "",
    clientId: "",
    clientName: "",
    clientAddress: "",
  },
};

const reducer = (state: INewClient, action: { type: string; item: any }) => {
  switch (action.type) {
    case "add_item":
      return {
        ...state,
        parameterData: { ...state.parameterData, ...action.item },
      };
    case "run_query":
      return { ...state, isSubmitted: action.item };
    case "reset":
      return { ...initialNewClient };
    default:
      return state;
  }
};

interface IAddNewClient {
  setClientRequest: (request: Omit<IClientItems, "reference">) => void;
  formErrorMessage: string;
  setFormErrorMessage: (value: string) => void;
  networkActionRef: boolean;
  existingClients: IClientItems[];
}

export const AddNewClient = ({ setClientRequest, formErrorMessage, networkActionRef, existingClients }: IAddNewClient) => {
  const [state, dispatch] = useReducer(reducer, initialNewClient);
  const [isExistingCompany, setIsExistingCompany] = useState(true);

  const handleUpdateClientEmail = (value: string) => {
    dispatch({ type: "add_item", item: { clientEmail: value } });
  };
  const handleUpdateClientId = (value: string) => {
    dispatch({ type: "add_item", item: { clientId: value } });
  };
  const handleUpdateClientName = (value: string) => {
    dispatch({ type: "add_item", item: { clientName: value } });
  };
  const handleUpdateClientAddress = (value: string) => {
    dispatch({ type: "add_item", item: { clientAddress: value } });
  };

  const handleUpdateRequest = () => {
    const request = {
      ...state.parameterData,
    };
    setClientRequest(request);
  };

  const handleResetState = () => {
    handleUpdateClientEmail("");
    handleUpdateClientName("");
    handleUpdateClientAddress("");
    handleUpdateClientId("");
  };

  useEffect(() => {
    handleUpdateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    return () => {
      setClientRequest({
        clientEmail: "",
        clientId: "",
        clientName: "",
        clientAddress: "",
      });
      handleResetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box paddingBottom={4}>
      <FormControl>
        <HStack space={w8}>
          <View>
            {/* <FormControl.Label
            _text={{
              fontSize: "sm",
              bold: true,
            }}>
            Existing Company ?
          </FormControl.Label> */}
            <Radio.Group
              name="Existing Company ?"
              value={isExistingCompany ? "1" : "2"}
              onChange={(value) => {
                setIsExistingCompany(value === "1" ? true : false);
                handleResetState();
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Existing Company
                </Radio>
                <Radio value="2" my="2">
                  New Company
                </Radio>
              </HStack>
            </Radio.Group>
          </View>
          {isExistingCompany && (
            <div>
              <TextField
                // id="outlined-select-currency"
                select
                size={"small"}
                label="Select Existing Client +"
                // value={}
                style={{ width: 300 }}
                // style={{ width: "100%" }}
                // placeholder="New +"
                // helperText="Add New"
              >
                {existingClients.map((option) => {
                  const { clientId, clientName, clientAddress } = option;

                  const handleUpdateExistingClient = () => {
                    handleUpdateClientId(clientId);
                    handleUpdateClientAddress(clientAddress);
                    handleUpdateClientName(clientName);
                  };

                  return (
                    <MenuItem key={clientName} value={clientName} onClick={handleUpdateExistingClient}>
                      {clientId} - {clientName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          )}
        </HStack>

        <div>
          <FormControl.Label>Client Id *</FormControl.Label>
          <Input
            autoCapitalize={"none"}
            onChangeText={handleUpdateClientId}
            placeholder={"id"}
            value={state.parameterData.clientId}
            isDisabled={isExistingCompany}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: 300,
            }}
          />
          {formErrorMessage !== "" && formErrorMessage.includes("id") ? (
            <View>
              <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                **{formErrorMessage}
              </Text>
            </View>
          ) : null}

          <FormControl.Label>Client Name *</FormControl.Label>
          <Input
            autoCapitalize={"none"}
            onChangeText={handleUpdateClientName}
            placeholder={"name"}
            value={state.parameterData.clientName}
            isDisabled={isExistingCompany}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: 300,
            }}
          />
          {formErrorMessage !== "" && formErrorMessage.includes("name") ? (
            <View>
              <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
                **{formErrorMessage}
              </Text>
            </View>
          ) : null}

          <FormControl.Label>Client Address</FormControl.Label>
          <Input
            autoCapitalize={"none"}
            onChangeText={handleUpdateClientAddress}
            placeholder={"Address"}
            value={state.parameterData.clientAddress}
            isDisabled={isExistingCompany}
            variant="outline"
            style={{
              padding: w16,
              borderRadius: 4,
              width: 300,
            }}
          />
        </div>

        <FormControl.Label>Client Email *</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          onChangeText={handleUpdateClientEmail}
          placeholder={"email"}
          value={state.parameterData.clientEmail}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
        {formErrorMessage !== "" && formErrorMessage.includes("email") ? (
          <View>
            <Text textAlign={"start"} fontSize={"2xs"} color={"warning.900"}>
              **{formErrorMessage}
            </Text>
          </View>
        ) : null}
      </FormControl>

      {/* view show loading */}
      {/* {networkActionRef === true ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null} */}
      <Backdrop sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }} open={networkActionRef === true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
