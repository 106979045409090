import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Divider, HStack } from "native-base";
import React from "react";

import { IPermissionItems } from "../../pages/permission/DashboardPermission";
import { w2 } from "../../styles";
import { handleDummyFunction } from "../../utils";
import { ListingText } from "../text";

interface IPermissionCardProps {
  cardItem: IPermissionItems;
  //   handleShowSampleDetail: () => void;
  setDeleteOpen: (value: boolean) => void;
  setEditOpen: (value: boolean) => void;
  setSelectedPermission: (value: IPermissionItems) => void;
}

export const PermissionCard: React.FC<IPermissionCardProps> = ({
  //   handleShowSampleDetail,
  cardItem,
  setDeleteOpen,
  setSelectedPermission,
  setEditOpen,
}) => {
  const { clientEmail, isAllowed, isClient, adminPermissionLevel } = cardItem;

  const handleDeleteAction = () => {
    setDeleteOpen(true);
    setSelectedPermission(cardItem);
  };

  const handleEditAction = () => {
    setEditOpen(true);
    setSelectedPermission(cardItem);
  };

  return (
    <div onClick={handleDummyFunction} style={{ cursor: "pointer" }}>
      <Box px="2" py="2" paddingRight={"4"} borderRadius={4} mx="auto" backgroundColor="white" width={window.innerWidth - 250}>
        <HStack
          space={4}
          // justifyContent="space-between"
          alignItems="center"
          divider={
            <Divider
              my="2"
              thickness={1}
              _light={{
                bg: "tertiary.500",
              }}
            />
          }>
          <ListingText label={isClient ? "Client" : "Admin"} flexValue={0.5} />
          <ListingText label={clientEmail} flexValue={2} />
          <ListingText label={isAllowed ? "Allowed" : "Blocked"} flexValue={0.5} />
          <ListingText label={isClient ? "-" : adminPermissionLevel} flexValue={0.5} style={{ textAlign: "center" }} />
          <HStack space={w2} flex={0.5} justifyContent={"center"}>
            <DeleteIcon
              onClick={() => {
                handleDeleteAction();
              }}
            />
            <EditIcon
              onClick={() => {
                handleEditAction();
              }}
            />
          </HStack>
        </HStack>
      </Box>
    </div>
  );
};
