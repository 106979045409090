import { Box, ScrollView, Text, VStack } from "native-base";
import React, { Fragment } from "react";

import { ParameterCard, Spacer } from "../../components";

interface IListingParameter {
  parameters: IParameterItems[];
}

export const ListingParameter = ({ parameters }: IListingParameter) => {
  const uniqueCategories: string[] = [];
  parameters
    .map((eachParameter) => {
      return eachParameter.parameterCategory;
    })
    .sort()
    .forEach((eachCategory) => {
      if (uniqueCategories.indexOf(eachCategory) === -1) {
        uniqueCategories.push(eachCategory);
      }
    });

  return (
    <Fragment>
      <ScrollView>
        {/* show completed item */}
        <Box height={4} />

        <VStack space="4">
          {uniqueCategories.sort().map((eachCategory) => {
            return (
              <Fragment>
                <Box alignItems={"center"} backgroundColor={"amber.300"} padding={2} justifyContent={"center"}>
                  <Text fontWeight={"semibold"}>{eachCategory}</Text>
                  <Spacer space={8} />
                </Box>

                <Box>
                  {parameters
                    .filter((eachParameter) => eachParameter.parameterCategory === eachCategory)
                    .map((eachParameter, index) => {
                      return (
                        <Box marginBottom={8} marginRight={12} flex={1}>
                          <ParameterCard
                            key={`${index}${eachParameter.reference}`}
                            // {...sampleCardProps}
                            cardItem={eachParameter}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Fragment>
            );
          })}
        </VStack>
      </ScrollView>
    </Fragment>
  );
};
