import { Box, FormControl, HStack, Input, Radio, View } from "native-base";
import React, { useEffect, useState } from "react";

import { w16 } from "../../styles";
import { IPermissionItems } from "./DashboardPermission";

interface IUpdatePermission {
  setPermissionRequest: (request: Omit<IPermissionItems, "reference">) => void;
  selectedPermission: IPermissionItems | undefined;
}

export const UpdatePermission = ({ setPermissionRequest, selectedPermission }: IUpdatePermission) => {
  //   const [clientEmail, setClientEmail] = useState<string>(
  //     selectedPermission?.clientEmail !== undefined ? selectedPermission?.clientEmail : "",
  //   );
  //   const [isClient, setIsClient] = useState<boolean>(selectedPermission?.isClient !== undefined ? selectedPermission?.isClient : false);
  const [isAllowed, setIsAllowed] = useState<boolean>(selectedPermission?.isAllowed !== undefined ? selectedPermission?.isAllowed : false);
  const [adminPermissionLevel, setAdminPermissionLevel] = useState<string>(
    selectedPermission?.adminPermissionLevel !== undefined ? selectedPermission?.adminPermissionLevel : "1",
  );
  const isClientRadioGroupValue = selectedPermission?.isClient !== undefined ? selectedPermission.isClient : false;

  //   const handleUpdateClientEmail = (value: string) => {
  //     setClientEmail(value);
  //   };
  //   const handleUpdateIsClient = (value: boolean) => {
  //     setIsClient(value);
  //   };
  const handleUpdateIsAllowed = (value: boolean) => {
    setIsAllowed(value);
  };
  const handleUpdateAdminPermissionLevel = (value: string) => {
    setAdminPermissionLevel(value);
  };

  const handleUpdateRequest = () => {
    const request = {
      clientEmail: selectedPermission?.clientEmail !== undefined ? selectedPermission?.clientEmail : "",
      isClient: isClientRadioGroupValue,
      isAllowed: isAllowed,
      clientId: selectedPermission?.clientId !== undefined ? selectedPermission?.clientId : "",
      adminPermissionLevel: adminPermissionLevel,
    };
    // console.log("request", request);
    setPermissionRequest(request);
  };

  useEffect(() => {
    handleUpdateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowed, adminPermissionLevel]);

  return (
    <Box paddingBottom={4}>
      <FormControl>
        <FormControl.Label>Client Email</FormControl.Label>
        <Input
          autoCapitalize={"none"}
          //   onChangeText={handleUpdateClientEmail}
          placeholder={"email"}
          value={selectedPermission?.clientEmail !== undefined ? selectedPermission?.clientEmail : ""}
          //   value={clientEmail}
          isDisabled={true}
          variant="outline"
          style={{
            padding: w16,
            borderRadius: 4,
            width: 300,
          }}
        />
      </FormControl>

      <HStack space={w16}>
        <View>
          <FormControl.Label
            _text={{
              fontSize: "sm",
              bold: true,
            }}>
            For Client ?
          </FormControl.Label>
          <Radio.Group
            name="For Client ?"
            value={isClientRadioGroupValue ? "1" : "2"}
            // onChange={(value) => {
            //   handleUpdateIsClient(value === "1" ? true : false);
            // }}
          >
            <HStack space={4} alignItems={"center"}>
              <Radio value="1" my="1" isDisabled={true}>
                Yes
              </Radio>
              <Radio value="2" my="2" isDisabled={true}>
                No
              </Radio>
            </HStack>
          </Radio.Group>
        </View>

        {!isClientRadioGroupValue ? (
          <View>
            <FormControl.Label
              _text={{
                fontSize: "sm",
                bold: true,
              }}>
              Permission Level ?
            </FormControl.Label>
            <Radio.Group
              name="Permission Level ?"
              value={adminPermissionLevel}
              onChange={(value) => {
                handleUpdateAdminPermissionLevel(value);
              }}>
              <HStack space={4} alignItems={"center"}>
                <Radio value="1" my="1">
                  Level 1
                </Radio>
                <Radio value="2" my="2">
                  Level 2
                </Radio>
              </HStack>
            </Radio.Group>
          </View>
        ) : null}
      </HStack>

      <FormControl.Label
        _text={{
          fontSize: "sm",
          bold: true,
        }}>
        Is Allowed ?
      </FormControl.Label>
      <Radio.Group
        name="Is Allowed ?"
        value={isAllowed ? "1" : "2"}
        onChange={(value) => {
          handleUpdateIsAllowed(value === "1" ? true : false);
        }}>
        <HStack space={4} alignItems={"center"}>
          <Radio value="1" my="1">
            Yes
          </Radio>
          <Radio value="2" my="1">
            No
          </Radio>
        </HStack>
      </Radio.Group>

      {/* view show loading */}
      {/* {state.isSubmitted ? (
        <Box position={"absolute"} backgroundColor={"gray.300"} width={window.innerWidth} height={window.innerHeight} opacity={0.7}>
          <Loading />
        </Box>
      ) : null} */}
    </Box>
  );
};
