import { Box, Text } from "native-base";

interface ILabelledMultipleItemBoxed {
  label: string;
  item: string | number;
}

export const TitledText = ({ label, item }: ILabelledMultipleItemBoxed) => {
  return (
    <Box flexDirection={"row"} flex={1}>
      <Text fontWeight={"semibold"}>{label}: </Text>
      <Text fontSize={"md"}>{item}</Text>
    </Box>
  );
};
