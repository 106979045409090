import React from "react";

interface ISpacer {
  isHorizontal?: boolean;
  space: number;
}

export const Spacer = ({ isHorizontal = false, space = 0 }: ISpacer) => {
  const style = isHorizontal === true ? { width: space } : { height: space };
  return <div style={style} />;
};
