import { Box, HStack, Text } from "native-base";
import React from "react";

interface ILabelledItemBoxed {
  label: string;
  item: string | undefined;
}

export const LabelledItemBoxed = ({ label, item }: ILabelledItemBoxed) => {
  return (
    <HStack flex={1} borderTopWidth={1} py={0.5}>
      <Box borderRightWidth={1}>
        <Text paddingLeft={2} fontWeight={"semibold"} width={180}>
          {label}
        </Text>
      </Box>

      <Text paddingLeft={2}>{item !== undefined ? item : "-"}</Text>
    </HStack>
  );
};
